import styled from "styled-components";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

export const FilesP6Icon = styled(PhaseSixIcon)`
    font-size: ${({ theme: { base } }) => base.spacingBig};
    cursor: inherit;
`;

export const FilesList = styled.div`
    display: flex;
    flex-direction: row;
    margin: -${({ theme: { base } }) => base.spacingSmall};
    margin-bottom: 0;
`;

export const DeleteIcon = styled.button`
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    color: ${({ theme: { colors } }) => colors.text};
    font-size: ${({ theme: { base } }) => base.smallSize};
    opacity: 0;
    transition: ${({ theme: { base } }) => base.transition}s;
    background: 0;
    padding: 0;
    border: 0;

    &:hover {
        transform: scale(1.2);
    }

    &:focus {
        opacity: 1;
    }
`;

export const FileItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 80px;
    height: 80px;
    margin: ${({ theme: { base } }) => base.spacingSmall};
    color: ${({ theme: { colors } }) => colors.text};
    font-size: ${({ theme: { base } }) => base.iconBig};
    line-height: 1;
    cursor: pointer;

    &:hover {
        > ${DeleteIcon} {
            opacity: 1;
        }
    }
`;

export const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export const UploadWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export const UploadButton = styled.button`
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: none;
    border: 1px solid transparent;
    color: ${({ theme: { colors } }) => colors.text};
    font-size: ${({ theme: { base } }) => base.iconSmall};
    cursor: pointer;
    border-radius: ${({ theme: { base } }) => base.borderRadius};

    &:hover {
        border-color: ${({ theme: { colors } }) => colors.border};
    }

    &:focus-visible {
        outline: 2px solid black;
    }
`;

export const UploadInput = styled.input`
    display: none;
`;

export const StyledButton = styled.button<{ isImage?: boolean }>`
    border: 0;
    background: 0;
    padding: 0;
    display: flex;
    cursor: pointer;
    height: ${({ isImage }) => (isImage ? "100%" : "auto")};
`;
