// LIBRARIES
import React from "react";
import { useDispatch, useSelector } from "react-redux";

// SLICES
import { actions as modalActions } from "../../../redux/modal/modalSlice";
import { selectors as userSelectors } from "../../../redux/user/userSlice";

// HELPERS
import { getUrlForIncludedContentSelection } from "../../../helpers/Url";
import { GlobalModalView } from "../../../helpers/Modal";

// COMPONENT
import SelectIncludedContentModal from "./SelectIncludedContentModal";

const ShareModalWrapper: React.FC = () => {
    const jossoId = useSelector(userSelectors.jossoId);
    const addUrl = getUrlForIncludedContentSelection(jossoId || "");

    const dispatch = useDispatch();

    const close = () => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
    };

    return (
        <SelectIncludedContentModal
            url={addUrl}
            close={close}
        />
    );
};

export default ShareModalWrapper;
