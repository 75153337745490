// LIBRARIES
import React, { useState, useEffect, useCallback } from "react";
import { useT } from "@transifex/react";

// COMPONENTS
import {
    Background,
    Overlay,
    LoginContainer,
    LinkContainer,
    StyledSidneyCheck,
    Message,
    AgreementLink,
} from "./styles";
import { ReactComponent as Phase6Logo } from "../../assets/img/p6_logo.svg";
import TextInput from "../../components/basic/textInput/TextInput";
import CheckboxInput from "../../components/basic/checkboxInput/CheckboxInput";
import PrimaryButton from "../../components/basic/primaryButton/PrimaryButton";
import SimpleLink from "../../components/basic/simpleLink/SimpleLink";

// HELPER
import { enterKeyListener } from "../../helpers/KeyListener";
import { LoginWarnings } from "../../redux/login/loginSlice";

export interface LoginProps {
    onLogin: (username: string, password: string, remember: boolean) => void;
    warning: LoginWarnings;
}

const Login: React.FC<LoginProps> = (props) => {
    const t = useT();

    const [usernameInput, setUsernameInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [keepLoggedInInput, setKeepLoggedInInput] = useState(true);

    const loginButtonText = t("Login", { _tags: "label,button" });
    const passwordForgottenText = t("Password forgotten?", { _tags: "link,button" });
    const registerText = t("Register", { _tags: "link,button" });
    const emailText = t("Email", { _tags: "input,placeholder" });
    const passwordText = t("Password", { _tags: "input,placeholder" });
    const stayLoggedInText = t("Stay Logged In?", { _tags: "checkmark" });

    const login = async () => {
        props.onLogin(usernameInput, passwordInput, keepLoggedInInput);
    };

    const acceptAgreement = t("accept agreement", { _tags: "message,login,warning" });
    const agreementLink = <AgreementLink onClick={login}>{acceptAgreement}</AgreementLink>;
    const loginAgbWarning = t("In order to obtain access you need to {agreementLink}", {
        _tags: "message,login,warning",
        agreementLink,
    });
    const loginChangedWarning = t("Your login data changed. Please log in with your new data.", {
        _tags: "message,login,warning",
    });

    // set key listener for login on "enter" - used state values must be dependencies to use login with the most recent state
    const loginWithCallback = useCallback(login, [usernameInput, passwordInput, keepLoggedInInput]);
    useEffect(() => {
        return enterKeyListener(loginWithCallback);
    }, [loginWithCallback]);

    useEffect(() => {
        if (window && (window as any)._sva) {
            const _sva = (window as any)._sva;
            _sva.destroyVisitor(_sva.retarget());
        }
    }, []);

    let message: JSX.Element | null;
    switch (props.warning) {
        case LoginWarnings.Agreement:
            message = <Message>{loginAgbWarning}</Message>;
            break;
        case LoginWarnings.LoginDataChanged:
            message = <Message>{loginChangedWarning}</Message>;
            break;
        default:
            message = null;
    }

    return (
        <Background>
            <Overlay>
                <LoginContainer>
                    <Phase6Logo title="Phase 6 Logo" />
                    <StyledSidneyCheck title="Sidney Check" />
                    <TextInput
                        id="email-input"
                        label={emailText}
                        placeholder={emailText}
                        value={usernameInput}
                        type="e-mail"
                        iconName="mail"
                        onChangeInput={(value) => setUsernameInput(value)}
                    />
                    <TextInput
                        id="password-input"
                        label={passwordText}
                        placeholder={passwordText}
                        value={passwordInput}
                        type="password"
                        iconName="lock"
                        onChangeInput={(value) => setPasswordInput(value)}
                    />
                    {message}
                    <CheckboxInput
                        label={stayLoggedInText}
                        checked={keepLoggedInInput}
                        onChange={(checked) => {
                            setKeepLoggedInInput(checked);
                        }}
                    />
                    <PrimaryButton onClick={login}>{loginButtonText}</PrimaryButton>
                    <LinkContainer>
                        <SimpleLink
                            href="https://www.phase-6.de/classic/service/user/login.html"
                            target="_blank"
                        >
                            {passwordForgottenText}
                        </SimpleLink>
                        <SimpleLink
                            href="https://www.phase-6.de/classic/shop/?demoMode=true"
                            target="_blank"
                        >
                            {registerText}
                        </SimpleLink>
                    </LinkContainer>
                </LoginContainer>
            </Overlay>
        </Background>
    );
};

export default Login;
