// REACT
import React, { FunctionComponent, useEffect, useMemo, useRef } from "react";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { selectors as userSelectors, actions as userActions } from "../../../../../redux/user/userSlice";
import { selectors } from "../../../../../redux/learning/learningSlice";

// TYPES
import { Props as ModalProps } from "../../../../basic/modal/Modal";

// COMPONENTS
import Component, { Props as ComponentProps } from "./Phase";

// UTILS
import { isModalOpen } from "../../../../../helpers/Modal";

// HOOKS
import { useFirstPracticeResult } from "../../../../../hooks/useFirstPracticeResult";

export const Phase: FunctionComponent<Partial<ModalProps>> = (props) => {
    const dispatch = useDispatch();
    const preferencesPhases = useSelector(userSelectors.getUserPhases);
    const phase = useSelector(selectors.cardPhase);
    const hasFirstPractice = useFirstPracticeResult();

    const buttonRef = useRef<HTMLButtonElement>();

    useEffect(
        function () {
            if (isModalOpen()) return;

            const tabListener = (e: KeyboardEvent) => {
                if (e.code !== "Tab") return;
                e.preventDefault();
            };

            if (!buttonRef.current) return;

            document.addEventListener("keydown", tabListener);
            buttonRef.current.focus();
            return function () {
                document.removeEventListener("keydown", tabListener);
            };
        },
        [buttonRef.current]
    );

    useEffect(() => {
        if (typeof phase === "number") {
            dispatch(userActions.setSeenPhaseModalInMetaData(phase));
        }
    }, [phase]);

    const userPhases: ComponentProps["userPhases"] = useMemo(() => {
        return preferencesPhases.slice(1).map(({ durationDays }, i) => ({
            name: i + 1,
            duration: `+${durationDays}`,
        }));
    }, [preferencesPhases]);

    return (
        <Component
            phase={phase}
            userPhases={userPhases}
            hasFirstPractice={hasFirstPractice}
            buttonRef={buttonRef}
            {...props}
        />
    );
};
