import styled, { keyframes } from "styled-components";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

const recordAnimation = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

export const StyledButton = styled.button<{ disabled?: boolean }>`
    color: inherit;
    cursor: inherit;
    background: 0;
    padding: 0;
    border: 0;

    ${({ disabled = false }) =>
        disabled
            ? `
            opacity: 0.5;
            cursor: no-drop;
        `
            : ""}
`;

export const Record = styled.div`
    animation-name: ${recordAnimation};
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    &:hover {
        color: #ac3248;
    }
`;

export const TooltipText = styled.p`
    margin: 0;
    width: 200px;
    white-space: break-spaces;
`;

export const RedPhaseSixIcon = styled(PhaseSixIcon)`
    color: #ac3248;
`;
