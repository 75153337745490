import axios from "axios";

export const upload = (files: File[], onUploadProgress?: (event: any) => void) => {
    let formData = new FormData();

    files.forEach((file: File) => formData.append("file[]", file));

    return axios.post("/media", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    });
};
