// REACT
import React, { FunctionComponent, useState, useCallback, KeyboardEvent } from "react";

// LIBRARIES
import { T } from "@transifex/react";
import { useFocusScope } from "react-focus-lock";

// COMPONENTS
import Component from "./LanguageSelect";

// STYLED COMPONENTS
import { PopupContainer } from "../../popup/styles";
import { MenuWrapper, MenuTitle, MenuItem, StyledPhaseSixIcon, MenuButton } from "./styles";

export type Props = {
    lang: string;
    langList: { [key: string]: string };
    onLangSelect: (selectedLang: string) => void;
};

export const LanguageSelect: FunctionComponent<Props> = (props) => {
    const { lang, langList, onLangSelect } = props;
    const [isOpen, setOpen] = useState<boolean>(false);

    const onPopupToggle = useCallback((isOpen: boolean) => {
        setOpen(isOpen);
    }, []);

    const menu = useCallback(
        ({ closePopup }: any) => {
            const { focusNext, focusPrev } = useFocusScope();

            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === "Tab") {
                    event.preventDefault();
                    closePopup();
                }
                if (event.key === "ArrowDown") {
                    event.preventDefault();
                    focusNext();
                }
                if (event.key === "ArrowUp") {
                    event.preventDefault();
                    focusPrev();
                }
            };

            return (
                <>
                    <PopupContainer>
                        <MenuTitle>
                            <T _str="Select language:" />
                        </MenuTitle>
                    </PopupContainer>

                    <MenuWrapper onKeyDown={handleKeyDown}>
                        {Object.entries(langList).map(([key, value]) => {
                            const isSelected: boolean = value === lang;
                            return (
                                <MenuItem key={key}>
                                    <MenuButton
                                        onClick={() => {
                                            onLangSelect(key);
                                            closePopup();
                                        }}
                                        selected={isSelected}
                                        autoFocus={isSelected}
                                    >
                                        {isSelected && <StyledPhaseSixIcon name="exercise-ok" />}&nbsp;
                                        <T _str={value} />
                                    </MenuButton>
                                </MenuItem>
                            );
                        })}
                    </MenuWrapper>
                </>
            );
        },
        [langList, onLangSelect, lang]
    );

    return (
        <Component
            isOpen={isOpen}
            menu={menu}
            selectedLang={lang}
            onPopupToggle={onPopupToggle}
        />
    );
};
