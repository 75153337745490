// REACT
import React, { FunctionComponent, useState, memo, useContext } from "react";
import { T, useT } from "@transifex/react";

// TYPES
import { AvatarGroup, Avatar } from "p6m-avatars";

// COMPONENTS
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";
import UserAvatar from "../../basic/userAvatar/UserAvatar";
import { AvatarsContext, RequirementNames, RequirementText } from "../../../context/avatars";

// STYLED COMPONENTS
import {
    Item,
    AvatarInfo,
    OtherInfo,
    AvatarTitle,
    AvatarTitleIconContainer,
    AchievementsInfo,
    AchievementsProgress,
    AchievementsProgressBar,
    AchievementsTitle,
    VariantsWrapper,
    VariantsList,
    VariantsItem,
} from "./styles";

type Props = {
    group: AvatarGroup;
    userAvatar?: Avatar;
    onClick: (avatar: Avatar) => void;
};

declare type RequirementNamesType = keyof typeof RequirementNames;

const AvatarGroupComponent: FunctionComponent<Props> = (props) => {
    const t = useT();
    const avatarContext = useContext(AvatarsContext);
    const { userAvatar, onClick, group } = props;
    const { isAvailable, name, userAvatars = [], requirementInformation = [] } = group;
    const active = userAvatars.find((findAvatar) => findAvatar === userAvatar);
    const [selected, setSelected] = useState(active || userAvatars[0]);

    return (
        <Item
            onClick={() => isAvailable && onClick(selected)}
            $active={!!active}
            $disabled={!isAvailable}
            data-qa="settings-avatars_group"
        >
            <AvatarInfo>
                <UserAvatar
                    userAvatar={selected.link}
                    size={"medium"}
                />
                <OtherInfo>
                    <AvatarTitle data-qa="settings-avatars_group-title">
                        <h2>
                            <T
                                _str={name}
                                _tags="avatars"
                            />
                        </h2>
                        <AvatarTitleIconContainer>
                            <PhaseSixIcon
                                name="exercise-ok"
                                highlighted
                            />
                        </AvatarTitleIconContainer>
                    </AvatarTitle>
                    {requirementInformation.map(({ neededProgress, ruleProcessorName: requirementName }) => {
                        const avatarDescription = avatarContext.getTranslation(
                            RequirementNames[requirementName as RequirementNamesType],
                            RequirementText.description,
                            neededProgress
                        );
                        return (
                            <p
                                key={requirementName}
                                data-qa="settings-avatars_group-rulename"
                            >
                                {avatarDescription}
                            </p>
                        );
                    })}
                    {requirementInformation.map((requirement) => {
                        const {
                            progress: totalProgress,
                            ruleProcessorName: requirementName,
                            currentProgress,
                        } = requirement;

                        const completionStatus = isAvailable ? "finished" : "incomplete";
                        const avatarDetails = avatarContext.getTranslation(
                            RequirementNames[requirementName as RequirementNamesType],
                            RequirementText[completionStatus],
                            currentProgress
                        );

                        return (
                            <AchievementsInfo
                                data-qa="settings-avatars_group-rule"
                                key={requirementName}
                            >
                                {!isAvailable && (
                                    <AchievementsProgress data-qa="settings-avatars_group-progress">
                                        <AchievementsProgressBar
                                            data-qa="settings-avatars_group-progressbar"
                                            $width={totalProgress}
                                        />
                                    </AchievementsProgress>
                                )}
                                <AchievementsTitle data-qa="settings-avatars_group-description">
                                    <p>{avatarDetails}</p>
                                </AchievementsTitle>
                            </AchievementsInfo>
                        );
                    })}
                </OtherInfo>
            </AvatarInfo>
            {isAvailable && userAvatars.length > 1 && (
                <VariantsWrapper>
                    <VariantsList data-qa="settings-avatars_variants">
                        {userAvatars.map((avatar, i) => {
                            const { link, avatarId } = avatar;
                            return (
                                <VariantsItem
                                    data-qa="settings-avatars_variant-item"
                                    key={avatarId}
                                    $active={avatar === selected}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        setSelected(avatar);
                                        onClick(avatar);
                                    }}
                                >
                                    <img
                                        src={link}
                                        alt={`${t("Avatar Variant", { _tags: "avatarGroup, altText" })} ${i + 1}`}
                                    />
                                    {active && active === avatar && (
                                        <AvatarTitleIconContainer>
                                            <PhaseSixIcon
                                                name="exercise-ok"
                                                highlighted
                                            />
                                        </AvatarTitleIconContainer>
                                    )}
                                </VariantsItem>
                            );
                        })}
                    </VariantsList>
                </VariantsWrapper>
            )}
        </Item>
    );
};

export default memo(AvatarGroupComponent);
