//DEPENDENCIES
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//REDUX
import { RootState } from "../../store/store";

//TYPES
import { GameSubject, CardsList } from "p6m-games";
import { WordMatchGameType } from "../../views/games/WordMatch/WordMatchGame";

const initialState: {
    2048: { value: any | false };
    matchGame: {
        subjects?: GameSubject[];
        gameCards?: CardsList["cards"];
    };
} = {
    2048: { value: false },
    matchGame: {
        subjects: undefined,
        gameCards: undefined,
    },
};

export const gamesSlice = createSlice({
    name: "games",
    initialState,
    reducers: {
        fetchMatchGame: () => {},
        fetchMatchGameCards: (
            _state,
            _action: PayloadAction<{ id: string; type: WordMatchGameType; loading?: boolean }>
        ) => {},
        fetchAddMatchGameScore: (_state, _action: PayloadAction<{ score: number; subject: GameSubject }>) => {},
        setMatchGameCards: (state, action: PayloadAction<CardsList["cards"]>) => {
            state.matchGame.gameCards = action.payload;
        },
        setMatchGameSubjects: (state, action: PayloadAction<GameSubject[]>) => {
            state.matchGame.subjects = action.payload;
        },
        setMatchGameScore: (
            state,
            { payload: { score, subjectId } }: PayloadAction<{ score: number; subjectId: string }>
        ) => {
            const subjects: GameSubject[] = state.matchGame.subjects || [];
            if (!subjects) return;
            const index: number = subjects.findIndex((subject) => {
                const { subjectMetadata: { subjectIdToOwner: { id } = {} } = {} } = subject;
                return subjectId === id;
            });

            if (index < 0) return;

            subjects[index].score.match = score;
        },
    },
});

export const { actions, reducer } = gamesSlice;

export const selectors = {
    matchGameSubjects: (state: RootState) => state.games.matchGame.subjects,
    matchGameSubjectById:
        (findId: string) =>
        (state: RootState): GameSubject | undefined => {
            if (!state.games.matchGame.subjects) return undefined;
            return state.games.matchGame.subjects.find((subject) => {
                const { subjectMetadata: { subjectIdToOwner: { id } = {} } = {} } = subject;
                return findId === id;
            });
        },
    matchGameCards: (state: RootState) => state.games.matchGame.gameCards,
};
