// LIBRARIES
import React from "react";

import ShareModal from "./ShareModal";
import { getShareAppUrl } from "../../../helpers/Url";
import { actions as modalActions } from "../../../redux/modal/modalSlice";
import { useDispatch } from "react-redux";
import { GlobalModalView } from "../../../helpers/Modal";

const ShareModalWrapper: React.FC = () => {
    const shareUrl = getShareAppUrl();

    const dispatch = useDispatch();

    const close = () => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
    };

    return (
        <ShareModal
            url={shareUrl}
            close={close}
        />
    );
};

export default ShareModalWrapper;
