import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { SubjectUnitCard, LibrarySubject } from "p6m-subjects";

interface LibraryState {
    subjectList: {
        subjects: LibrarySubject[];
        totalCardsInLibrary: number;
        filteredCardsCount: number;
        filteredCards: Record<string, SubjectUnitCard[]>;
    };
}

const initialState: LibraryState = {
    subjectList: {
        subjects: [],
        totalCardsInLibrary: 0,
        filteredCardsCount: -1,
        filteredCards: {},
    },
};

export const librarySlice = createSlice({
    name: "Library",
    initialState: initialState,
    reducers: {
        loadSubjectList: () => {},
        setSubjectList: (state, action: PayloadAction<{ subjects: LibrarySubject[]; totalCards: number }>) => {
            state.subjectList.subjects = action.payload.subjects;
            state.subjectList.totalCardsInLibrary = action.payload.totalCards;
        },
        searchSubjectListCards: (_state, _action: PayloadAction<string>) => {},
        setFilteredSubjectListCards: (
            state,
            action: PayloadAction<{ cards: Record<string, SubjectUnitCard[]>; totalCards: number }>
        ) => {
            state.subjectList.filteredCards = action.payload.cards;
            state.subjectList.filteredCardsCount = action.payload.totalCards;
        },
        resetSubjectListSearch: (state) => {
            state.subjectList.filteredCardsCount = -1;
            state.subjectList.filteredCards = {};
        },
        resetSubjectListState: (state) => {
            state.subjectList = initialState.subjectList;
        },
    },
});

export const { actions, reducer } = librarySlice;

export const subjectListSelectors = {
    getSubjects: (state: RootState) => state.library.subjectList.subjects,
    getTotalCardsInLibrary: (state: RootState) => state.library.subjectList.totalCardsInLibrary,
    getFilteredCards: (state: RootState) => state.library.subjectList.filteredCards,
    getFilteredCardsCount: (state: RootState) => state.library.subjectList.filteredCardsCount,
};
