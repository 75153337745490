import React, { FunctionComponent, memo, useContext } from "react";
import { T, useT } from "@transifex/react";
import { CoverImage } from "p6-react-shared";
import { Row, Col } from "../../../../basic/grid";
import { getSubjectCover } from "../../../../../helpers/Subjects";

import {
    Wrapper,
    CoverImageCol,
    FlagRow,
    FlagCol,
    Title,
    LanguageImage,
    TitleWrapper,
    ItemRow,
    ItemInformationCol,
} from "./styles";

import { LanguagesContext } from "../../../../../context/languages";

export type Props = {
    onSelect: () => void;
};

const DefaultSubjectItem: FunctionComponent<Props> = (props) => {
    const t = useT();
    const { getFlagSource } = useContext(LanguagesContext);
    const { onSelect } = props;

    const primaryLang = "de";
    const secondaryLang = "";

    const cover = getSubjectCover();

    return (
        <Wrapper
            $edit={false}
            onClick={onSelect}
        >
            <ItemRow vertical="center">
                <CoverImageCol xs="auto">
                    <CoverImage
                        imageId={cover}
                        size="small"
                        alt=""
                    />
                </CoverImageCol>
                <ItemInformationCol>
                    <Row>
                        <Col xs={12}>
                            <TitleWrapper>
                                <Title>
                                    <T _str="General" />
                                </Title>
                            </TitleWrapper>
                        </Col>
                    </Row>
                    <FlagRow>
                        <Col xs="auto">
                            <Row>
                                <FlagCol xs="auto">
                                    <LanguageImage
                                        src={getFlagSource(primaryLang)}
                                        alt={`${t("Source Language", {
                                            _tags: "subjectItem, languageImage, altText",
                                        })} ${primaryLang}`}
                                    />
                                </FlagCol>
                                <FlagCol xs="auto">
                                    <LanguageImage
                                        src={getFlagSource(secondaryLang)}
                                        alt={`${t("Target Language", {
                                            _tags: "subjectItem, languageImage, altText",
                                        })} ${secondaryLang}`}
                                    />
                                </FlagCol>
                            </Row>
                        </Col>
                    </FlagRow>
                </ItemInformationCol>
            </ItemRow>
        </Wrapper>
    );
};

export default memo(DefaultSubjectItem);
