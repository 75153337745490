// REACT
import React from "react";
import { useT } from "@transifex/react";

// TYPES
import { SubjectData } from "p6m-subjects";
import { CoverImage } from "p6-react-shared";

// UTILS
import { getSubjectCover } from "../../../helpers/Subjects";

// STYLED COMPONENTS
import { ActiveSubjectOverlay, Container } from "./styles";

export interface SubjectEntryProps {
    subject: SubjectData;
    userRoles?: { isTeacher: boolean; isParent: boolean };
    onSubjectSelect: (subjectName: string) => any;
    active: boolean;
}

const SubjectEntry: React.FC<SubjectEntryProps> = (props) => {
    const t = useT();
    const image = props.subject && getSubjectCover(props.subject);
    return (
        <Container
            key={"subject_" + props.subject.subjectMetadata.subjectIdToOwner.id}
            onClick={() => props.onSubjectSelect(props.subject.subjectMetadata.subjectIdToOwner.id)}
        >
            <CoverImage
                imageId={image || null}
                responsive
                alt={t("Subject Cover {x}", {
                    x: props.subject.subjectContent.name,
                    _tags: "subjectCarousel, home, altText",
                })}
            />
            {props.active ? <ActiveSubjectOverlay /> : null}
        </Container>
    );
};

SubjectEntry.defaultProps = {
    active: false,
};

export default SubjectEntry;
