import { SubjectUnitCard } from "p6m-subjects";

type Media = Record<"audios" | "images", string[]>;
type MediaKeys = keyof Media;
type MediaTypes = "audio" | "image";
const keyRelations: Record<MediaTypes, MediaKeys> = {
    audio: "audios",
    image: "images",
};
const typeRelations = Object.fromEntries(
    Object.entries(keyRelations).map(([key, value]) => [value, key]) as [MediaKeys, MediaTypes][]
) as Record<MediaKeys, MediaTypes>;

export function stripMediaIds(input: string) {
    return input.replace(/\[(.*?)]/g, "");
}

function getCardDataMatches(input: string): string[] {
    const media: string[] = [];
    const title = input.replace(/\[\{~(.+?)~\}\]/g, (match, mediaId) => {
        media.push(mediaId);
        return "";
    });

    return [title, ...media];
}

type CardContentInfo = {
    title: string;
    htmlTitle: string;
    media: string[];
} & Media;

export function clearCardTextFromHTML(text: string): string {
    text = text.replace(/<\/p><p>/gi, " "); // change all paragraphs to space
    text = text.replace(/<br ?\/?>/gi, " "); // all newline to space
    return text.replace(/(<([^>]+)>)/gi, "").trim(); // remove other tags
}

export function getCardContentInfo(input: string): CardContentInfo;
export function getCardContentInfo(input: string, key: keyof Pick<CardContentInfo, "htmlTitle" | "title">): string;
export function getCardContentInfo(input: string, key?: keyof Pick<CardContentInfo, "htmlTitle" | "title">) {
    const matches = getCardDataMatches(input);
    const media = matches.slice(1);

    const htmlTitle = matches[0];
    const title = clearCardTextFromHTML(htmlTitle);

    if (key) {
        return (
            {
                htmlTitle,
                title,
            } as Pick<CardContentInfo, "htmlTitle" | "title">
        )[key];
    }

    return media.reduce(
        (result, mediaValue) => {
            const mediaData = mediaValue.split(":");
            const key = keyRelations[mediaData[1] as MediaTypes] as MediaKeys;

            if (!key) return result;

            result[key].push(mediaData[0]);

            return result;
        },
        { htmlTitle, title, audios: [], images: [], media } as CardContentInfo
    );
}

export function createMediaSource(imageId: string) {
    const baseURL = process.env.REACT_APP_BACKEND_API_URL;
    return `${baseURL}media/${imageId}`;
}

type Status = "play" | "pause" | "stop";
type AudioOnChange = (isPlaing: Status) => void;
type AudioObj = {
    play: (onChange?: AudioOnChange) => void;
    pause: (onChange?: AudioOnChange) => void;
    stop: (onChange?: AudioOnChange) => void;
};
export function playAudioGenerator(audioId: string) {
    const url = createMediaSource(audioId);
    let audio: HTMLAudioElement | undefined;
    let status: Status;
    let callback: AudioOnChange;
    const play = (onChange?: AudioOnChange) => {
        if (!audio) {
            audio = new Audio(url);
            audio.addEventListener("ended", () => {
                audio = undefined;
                status = "stop";
                (onChange || callback)?.(status);
            });
        }

        audio.play();
        status = "play";
        (onChange || callback)?.(status);
    };
    const pause = (onChange?: AudioOnChange) => {
        if (!audio) return;
        audio.pause();
        status = "pause";
        (onChange || callback)?.(status);
    };
    const stop = (onChange?: AudioOnChange) => {
        if (audio) {
            audio.pause();
            audio = undefined;
        }
        status = "stop";
        (onChange || callback)?.(status);
    };
    function returnFn(): AudioObj;
    function returnFn(first: AudioOnChange): AudioObj;
    function returnFn(first: boolean, second?: AudioOnChange): void;
    function returnFn(first?: boolean | AudioOnChange, second?: AudioOnChange) {
        if (first === undefined || first instanceof Function) {
            if (first instanceof Function) {
                callback = first;
            }
            if (status) {
                callback?.(status);
            }
            return {
                play,
                pause,
                stop,
            } as AudioObj;
        }
        if (!audio || audio.paused) return play(second);
        if (first) return stop(second);
        pause(second);
    }

    return returnFn;
}

export function getCardData(title: string) {
    const data = getCardContentInfo(title);
    return {
        title: data.title,
        htmlTitle: data.htmlTitle,
        playAudios: data.audios.map((audio) => playAudioGenerator(audio)),
        audios: data.audios.map((audioId) => createMediaSource(audioId)),
        images: data.images.map((imageId) => createMediaSource(imageId)),
    };
}

export function generateCardMediaString(props: Partial<Media> | string[]): string {
    let ignoreType = false;
    if (Array.isArray(props)) {
        props = {
            audios: props,
        };
        ignoreType = true;
    }
    const { audios = [], images = [] } = props;
    if (!audios.length && !images.length) return "";

    const separators = {
        begin: "[{~",
        end: "~}]",
        type: ":",
    };

    const media: string = Object.entries({
        audios,
        images,
    })
        .filter(([, mediaIds]) => mediaIds.length)
        .map(([key, mediaIds]) => {
            if (!ignoreType) {
                mediaIds = mediaIds.map((mediaId) => `${mediaId}${separators.type}${typeRelations[key as MediaKeys]}`);
            }
            return separators.begin + mediaIds.join(separators.end + separators.begin) + separators.end;
        })
        .join("");

    return media;
}

export const getCardIdToOwnerListFromCardIds = (cardsIds: string[], cardDataList: SubjectUnitCard[]) => {
    return cardsIds.map((id) => {
        const card = cardDataList.find((c) => c.cardIdToOwner.id === id);
        if (card) return card.cardIdToOwner;
        return { id: "", ownerId: "" };
    });
};

type ValidateAnnotationProps = [string | undefined, string | undefined];
export function validateAnnotations(
    [question, answer]: ValidateAnnotationProps,
    [questionAnnotation, answerAnnotation]: ValidateAnnotationProps
) {
    // got it from old webapp
    const validatePair = function (text?: string, annotation?: string) {
        if (!text || !annotation) return true;
        return text.indexOf(annotation) < 0 && annotation.indexOf(text) < 0;
    };

    const stringParserFn = function (str?: string) {
        if (!str) return "";
        str = str.toLowerCase();
        str = str.replace(/[.,-/#!$%^&*;:{}=\-_`~()]/g, "");
        str = str.replace(/(<([^>]+)>)/gi, "");
        str = str.replace(/&nbsp;/g, "").replace(/\s/g, "");
        return str;
    };

    question = stringParserFn(question);
    answer = stringParserFn(answer);
    questionAnnotation = stringParserFn(questionAnnotation);
    answerAnnotation = stringParserFn(answerAnnotation);

    return validatePair(question, answerAnnotation) && validatePair(answer, questionAnnotation);
}
