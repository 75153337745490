// REACT
import React, { FunctionComponent, useMemo, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { actions as learningActions, selectors } from "../../../../../redux/learning/learningSlice";
import { selectors as userSelectors } from "../../../../../redux/user/userSlice";
import {
    selectors as subjectsSelector,
    selectors as subjectSelectors,
} from "../../../../../redux/subjects/subjectsSlice";
import { actions as goalsActions, selectors as goalsSelectors } from "../../../../../redux/goals/goalsSlice";
import { actions as modalActions } from "../../../../../redux/modal/modalSlice";

// TYPES
import { Props as ModalProps } from "../../../../basic/modal/Modal";

// COMPONENTS
import Component from "./Done";

// UTILS
import { ThemeContext } from "styled-components";
import { GlobalModalView } from "../../../../../helpers/Modal";

type Props = Partial<ModalProps> & { hasFirstPractice?: boolean };

export const Done: FunctionComponent<Props> = (props) => {
    const practiced = useSelector(selectors.filteredItems({ type: "practice" }));
    const subjectId = useSelector(selectors.subjectId);
    const trialTime = useSelector(subjectSelectors.getTrialTime(subjectId));
    const userId = useSelector(userSelectors.userId) || "";
    const isChild = useSelector(userSelectors.isChild);
    const theme = useContext(ThemeContext);
    const history = useHistory();
    const hasGrammarTutorPromotionEnabled = useSelector(userSelectors.hasGrammarTutorPromotionEnabled);
    const achievements = useSelector(goalsSelectors.goals);
    const possibleToday = useSelector(subjectsSelector.additionalCardsCanBePracticedToday);

    const dispatch = useDispatch();

    const subjectIsTrial: boolean = trialTime > 0;

    const total: number = practiced.length;

    const atFirst: number = useMemo(() => {
        return practiced.filter(({ submitCount }) => submitCount === 1).length;
    }, [practiced]);

    const userStars: number = 3;

    const backgroundColor = theme.colors.backgroundContrast;

    const onGoHome = useCallback(() => {
        dispatch(goalsActions.fetchGoals());
        // only show modal to users who have not yet finished a GT topic
        if (props.hasFirstPractice && hasGrammarTutorPromotionEnabled && !achievements?.goals.finished1GtTopic) {
            dispatch(modalActions.setModalView(GlobalModalView.PromoteGrammarTutor));
        }
        history.push("/home");
    }, [dispatch, history, hasGrammarTutorPromotionEnabled, props.hasFirstPractice]);

    const handlePracticeMore = useCallback(() => {
        dispatch(learningActions.startPracticeWithAdditionalCards({ subjectId, history }));
    }, [dispatch, history, subjectId]);

    return (
        <Component
            total={total}
            atFirst={atFirst}
            backgroundColor={backgroundColor}
            userId={userId}
            userStars={userStars}
            subjectId={subjectId}
            subjectIsTrial={subjectIsTrial}
            isChild={isChild}
            onGoHome={onGoHome}
            possibleToday={possibleToday}
            onPracticeMore={handlePracticeMore}
            {...props}
        />
    );
};
