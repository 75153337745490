// REACT
import React, { FunctionComponent, useMemo, useCallback, useRef, useEffect } from "react";
import { useHistory } from "react-router";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { selectors, actions } from "../../../../redux/learning/learningSlice";

// COMPONENTS
import Component from "./Header";

export const Header: FunctionComponent = () => {
    const practice = useSelector(selectors.filteredItems({ type: "practice" }));
    const memorize = useSelector(selectors.filteredItems({ type: ["drag", "memorize"] }));
    const phase = useSelector(selectors.cardPhase);
    const showExitFirstPracticeModal = useSelector(selectors.getShowExitFirstPracticeModal);
    const { type } = useSelector(selectors.currentItem) || {};
    const dragNDropResolvedCards = useSelector(selectors.gerDragNDropResolvedCards);

    const headerElementRef = useRef<HTMLDivElement>();

    const history = useHistory();
    const dispatch = useDispatch();

    const total = practice.length || memorize.length;
    const isDragNDrop: boolean = type === "drag";

    useEffect(
        function () {
            if (!showExitFirstPracticeModal) return;
            if (!headerElementRef.current) return;

            const element = headerElementRef.current;
            const listener: EventListener = function () {
                if (!showExitFirstPracticeModal) return;
                dispatch(actions.pushModal("exitFirstPractice"));
            };
            element.addEventListener("mouseenter", listener);

            return function () {
                if (!listener) return;
                element.removeEventListener("mouseenter", listener);
            };
        },
        [dispatch, showExitFirstPracticeModal, headerElementRef]
    );

    const practiced = useMemo(() => {
        if (!practice.length) return undefined;
        return practice.filter(({ resolved }) => !!resolved).length;
    }, [practice]);

    const memorized = useMemo(() => {
        if (!memorize.length) return undefined;
        if (isDragNDrop) return dragNDropResolvedCards;
        const memorized = memorize.filter(({ resolved }) => !!resolved);
        const bothMemorized = memorized.filter(({ direction }) => direction === "both");
        return memorized.length + bothMemorized.length;
    }, [memorize, isDragNDrop, dragNDropResolvedCards]);

    const onClose = useCallback(() => {
        const notMemorized = memorize.filter(({ resolved }) => !resolved);
        const notPracticed = practice.filter(({ resolved }) => !resolved);
        if (notMemorized.length) return dispatch(actions.pushModal("autoActivate"));
        if (notPracticed.length) return dispatch(actions.pushModal("exit"));
        history.push("/home");
    }, [dispatch, history, memorize, practice]);

    return (
        <Component
            ref={(refElement) => (headerElementRef.current = refElement || undefined)}
            total={total}
            practiced={practiced}
            memorised={memorized}
            cardPhase={phase}
            onClose={onClose}
        />
    );
};
