// REACT
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { T, useT } from "@transifex/react";
import { useHistory } from "react-router";

// COMPONENTS
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import MatchGameScoreIcon from "../../../basic/matchGameScoreIcon/MatchGameScoreIcon";
import Tooltip from "../../../basic/tooltip/Tooltip";
import { CoverImage } from "p6-react-shared";

// UTILS
import { getScoreByTime, getScoreTitle } from "../../../../helpers/MatchGame";
import timeFormat from "../../../../helpers/timeFormat";

// STYLED COMPONENTS
import {
    ItemBox,
    ItemInfo,
    ItemName,
    ItemScoreBlock,
    ScoreTime,
    ItemScoreName,
    HoverBlock,
    HoverButton,
    StyledPhaseSixIcon,
    ItemContent,
} from "./styles";

type Props = {
    id: string;
    image: string;
    name: string;
    hasActiveCards: boolean;
    time?: number;
    startNormal?: (id: string, e: any) => void;
    startExplorer?: (id: string, e: any) => void;
};

const SubjectItem: FunctionComponent<Props> = (props) => {
    const t = useT();

    const memoriseFirstTooltip = t("Have at least 24 active cards.", { _tags: "tooltip,wordMatchGame" });
    const activeCardsTooltip = t("Start with active cards only.", { _tags: "tooltip,wordMatchGame" });
    const allCardsSubjectTooltip = t("Start with all cards from the subject.", { _tags: "tooltip,wordMatchGame" });

    const { id, image, name, time = 0, hasActiveCards, startNormal, startExplorer } = props;
    const history = useHistory();
    const [scoreTitle, setScoreTitle] = useState<string>("");
    const [scoreValue, setScoreValue] = useState<number>(0);

    const formatedTime: string = useMemo(() => {
        return timeFormat(time);
    }, [time]);

    useEffect(() => {
        const score = getScoreByTime(time);
        setScoreValue(score);
        setScoreTitle(getScoreTitle(score));
    }, [time]);

    const goToActivation = (id: string) => {
        history.push(`/activation/${id}`);
    };

    return (
        <ItemBox>
            <ItemContent>
                <CoverImage
                    alt=""
                    size="medium"
                    imageId={image}
                />
                <ItemInfo>
                    <ItemName>{name}</ItemName>
                    <ItemScoreBlock>
                        <MatchGameScoreIcon time={time} />
                        <div>
                            {time === 0 ? (
                                <ScoreTime>
                                    <p>--:--</p>
                                </ScoreTime>
                            ) : (
                                <ScoreTime>
                                    {formatedTime}&nbsp;
                                    <PhaseSixIcon name="running-time" />
                                </ScoreTime>
                            )}
                            {scoreTitle && <ItemScoreName score={scoreValue}>{scoreTitle}</ItemScoreName>}
                        </div>
                    </ItemScoreBlock>
                </ItemInfo>
            </ItemContent>
            {(startNormal || startExplorer) && (
                <HoverBlock>
                    {!hasActiveCards && (
                        <Tooltip content={memoriseFirstTooltip}>
                            <HoverButton
                                inactive
                                position={"left"}
                                onClick={() => goToActivation(id)}
                            >
                                <StyledPhaseSixIcon name="annotation" />
                                <T _str="Memorise first" />
                            </HoverButton>
                        </Tooltip>
                    )}
                    {hasActiveCards && startNormal && (
                        <Tooltip content={activeCardsTooltip}>
                            <HoverButton
                                position={"left"}
                                onClick={(e) => startNormal(id, e)}
                            >
                                <StyledPhaseSixIcon name="play" />
                                <T _str="Normal" />
                            </HoverButton>
                        </Tooltip>
                    )}
                    {startExplorer && (
                        <Tooltip content={allCardsSubjectTooltip}>
                            <HoverButton
                                position={"right"}
                                onClick={(e) => startExplorer(id, e)}
                            >
                                <StyledPhaseSixIcon name="star-plus" />
                                <T _str="Explorer" />
                            </HoverButton>
                        </Tooltip>
                    )}
                </HoverBlock>
            )}
        </ItemBox>
    );
};

export default SubjectItem;
