// REACT
import React, { FunctionComponent, memo } from "react";
import { useT } from "@transifex/react";

// COMPONENTS
import Tooltip from "../../../basic/tooltip/Tooltip";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import { StyledButton, Record, RedPhaseSixIcon, TooltipText } from "./styles";

const MicIcon = () => (
    <div>
        <PhaseSixIcon name="mic" />
    </div>
);
const RecordIcon: FunctionComponent<Parameters<typeof Record>[0]> = (props) => (
    <Record {...props}>
        <RedPhaseSixIcon name="stop" />
    </Record>
);

type Props = {
    canRecord: boolean;
    isRecording: boolean;
    hasPermission: boolean;
    onStart: () => void;
    onStop: () => void;
};

const RecordAudio: FunctionComponent<Props> = (props) => {
    const { isRecording, canRecord, hasPermission, onStart, onStop } = props;
    const t = useT();

    const t_permission = t("To record something it is necessary to give us permission using your microphone.", {});

    const microphoneWithTooltip = hasPermission ? (
        <StyledButton
            onClick={onStart}
            disabled={!canRecord}
        >
            <MicIcon />
        </StyledButton>
    ) : (
        <Tooltip content={<TooltipText>{t_permission}</TooltipText>}>
            <StyledButton
                onClick={onStart}
                disabled={true}
            >
                <MicIcon />
            </StyledButton>
        </Tooltip>
    );

    return isRecording ? (
        <StyledButton onClick={onStop}>
            <RecordIcon />
        </StyledButton>
    ) : (
        microphoneWithTooltip
    );
};

export default memo(RecordAudio);
