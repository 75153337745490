import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { AddCardSnapshot, AddCardContent, SnapshotErrors, SharingCardsListResponse } from "p6m-cards";
import { selectors as subjectSelectors } from "../subjects/subjectsSlice";
import { clearCardTextFromHTML, validateAnnotations } from "../../helpers/Cards";
import { getCardContent } from "../../networking/cards";
import { selectors as userSelectors } from "../user/userSlice";

interface AddState {
    subjectId?: string;
    snapshot?: AddCardSnapshot;
    sharingContent?: SharingCardsListResponse["sharingContentDataMap"];
    dontShowConfirmEditModal: string[];
    firstCardReminderShownTo: string[];
    isSaving: boolean;
}

const defaultSnapshot: AddCardSnapshot = {
    subjectId: "default",
    unitId: "default",
    primaryLang: "de",
};

const initialState: AddState = {
    dontShowConfirmEditModal: [],
    firstCardReminderShownTo: [],
    isSaving: false,
};

export const addSlice = createSlice({
    name: "add",
    initialState,
    reducers: {
        saveAddCardSnapshot: (state, action: PayloadAction<Partial<AddCardSnapshot>>) => {
            const { payload } = action;
            state.snapshot = { ...defaultSnapshot, ...payload };
        },
        clearAddCardsSnapshot: (state) => {
            state.snapshot = { ...defaultSnapshot };
        },
        clearAddCardContents: (state) => {
            if (!state.snapshot) state.snapshot = { ...defaultSnapshot };
            state.snapshot.answer = {};
            state.snapshot.question = {};
            state.snapshot.ownerId = undefined;
        },
        setSubjectId: (state, action: PayloadAction<string>) => {
            state.subjectId = action.payload;
        },
        setCardSubjectId: (state, { payload }: PayloadAction<string>) => {
            if (!state.snapshot) state.snapshot = { ...defaultSnapshot };
            state.snapshot.subjectId = payload || "default";
            state.snapshot.unitId = "default";
        },
        setCardUnitId: (state, { payload }: PayloadAction<string>) => {
            if (!state.snapshot) state.snapshot = { ...defaultSnapshot };
            state.snapshot.unitId = payload || "default";
        },
        setCardId: (state, { payload }: PayloadAction<string | undefined>) => {
            if (!state.snapshot) state.snapshot = { ...defaultSnapshot };
            state.snapshot.cardId = payload;
        },
        setSnapshot: (state, { payload }: PayloadAction<AddCardSnapshot | undefined>) => {
            state.snapshot = payload || { ...defaultSnapshot };
        },
        setCardPrimaryLang: setCardLanguageGenerator("primaryLang"),
        setCardSecondaryLang: setCardLanguageGenerator("secondaryLang"),
        setQuestionContent: setCardContentGenerator("question"),
        setAnswerContent: setCardContentGenerator("answer"),
        setQuestionText: setCardContentDataGenerator("question", "text"),
        setAnswerText: setCardContentDataGenerator("answer", "text"),
        setQuestionAnnotation: setCardContentDataGenerator("question", "annotation"),
        setAnswerAnnotation: setCardContentDataGenerator("answer", "annotation"),
        setQuestionImages: setCardContentDataGenerator("question", "images"),
        setAnswerImages: setCardContentDataGenerator("answer", "images"),
        setQuestionAudios: setCardContentDataGenerator("question", "audios"),
        setAnswerAudios: setCardContentDataGenerator("answer", "audios"),
        setModal: (state, { payload }: PayloadAction<AddCardSnapshot["modal"]>) => {
            if (!state.snapshot) state.snapshot = { ...defaultSnapshot };
            state.snapshot.modal = payload;
        },
        saveCard: (
            _state,
            _action: PayloadAction<{
                hasQuestionAnnotationChanged: boolean;
                hasAnswerAnnotationChanged: boolean;
                isPublisherSubject?: () => boolean;
            }>
        ) => {},
        fetchCardContent: (_state, _action: PayloadAction<Parameters<typeof getCardContent>[0]>) => {},
        fetchSharingContent: () => {},
        setSharingContent: (state, { payload }: PayloadAction<SharingCardsListResponse["sharingContentDataMap"]>) => {
            state.sharingContent = payload;
        },
        updateSharingContentLink: (
            state,
            {
                payload,
            }: PayloadAction<{
                id: string;
                newLink: SharingCardsListResponse["sharingContentDataMap"][0]["shareLink"];
            }>
        ) => {
            const { id, newLink } = payload;
            if (!state.sharingContent) return;
            state.sharingContent[id].shareLink = newLink;
        },
        fetchShareLink: (_state, _action: PayloadAction<string>) => {},
        toggleDontShowConfirmEditModal: (state, { payload }: PayloadAction<string | undefined>) => {
            if (!payload) return;
            const dontShowConfirmEditModal = [...state.dontShowConfirmEditModal];
            const index = dontShowConfirmEditModal.indexOf(payload);
            if (index < 0) dontShowConfirmEditModal.push(payload);
            else dontShowConfirmEditModal.splice(index, 1);
            state.dontShowConfirmEditModal = dontShowConfirmEditModal;
        },
        setFirstCardReminder: (state, { payload }: PayloadAction<string | undefined>) => {
            if (!payload) return;
            const reminders = [...state.firstCardReminderShownTo];
            reminders.push(payload);
            state.firstCardReminderShownTo = reminders;
        },
        setSaving: (state, { payload }: PayloadAction<boolean>) => {
            state.isSaving = payload;
        },
    },
});

/* EXPORTS */
export const { actions, reducer } = addSlice;

export const selectors = {
    getAddCardsSnapshot: (state: RootState) => state.add.snapshot || { ...defaultSnapshot },
    subjectId: (state: RootState) => state.add.subjectId,
    getAddCardQuestion: (state: RootState) => state.add.snapshot?.question,
    getAddCardAnswer: (state: RootState) => state.add.snapshot?.answer,
    getAddCardQuestionText: (state: RootState) => state.add.snapshot?.question?.text || "",
    getAddCardQuestionRawText: (state: RootState) => clearCardTextFromHTML(state.add.snapshot?.question?.text || ""),
    getAddCardQuestionAnnotation: (state: RootState) => state.add.snapshot?.question?.annotation,
    getAddCardQuestionImages: (state: RootState) => state.add.snapshot?.question?.images,
    getAddCardQuestionAudios: (state: RootState) => state.add.snapshot?.question?.audios,
    getAddCardAnswerText: (state: RootState) => state.add.snapshot?.answer?.text || "",
    getAddCardAnswerRawText: (state: RootState) => clearCardTextFromHTML(state.add.snapshot?.answer?.text || ""),
    getAddCardAnswerAnnotation: (state: RootState) => state.add.snapshot?.answer?.annotation,
    getAddCardAnswerImages: (state: RootState) => state.add.snapshot?.answer?.images,
    getAddCardAnswerAudios: (state: RootState) => state.add.snapshot?.answer?.audios,
    getAddCardsPrimaryLang: getCardLanguage(0),
    getAddCardsSecondaryLang: getCardLanguage(1),
    getAddCardsSubjectId: (state: RootState) => state.add.snapshot?.subjectId || "default",
    getAddCardsUnitId: (state: RootState) => state.add.snapshot?.unitId || "default",
    getAddCardsCardId: (state: RootState) => state.add.snapshot?.cardId,
    validateSnapshot: (state: RootState): SnapshotErrors[] => {
        const { snapshot } = state.add;
        if (!snapshot) return ["EMPTY_SNAPSHOT"];
        const { question, answer, subjectId, unitId } = snapshot;
        const errorsRelation: Record<Exclude<SnapshotErrors, "EMPTY_SNAPSHOT">, boolean> = {
            NO_SUBJECT: !!subjectId,
            NO_UNIT: !!unitId,
            NO_QUESTION: !!clearCardTextFromHTML(question?.text || "").trim(),
            NO_ANSWER: !!clearCardTextFromHTML(answer?.text || "").trim(),
        };

        const errors = (Object.entries(errorsRelation) as [SnapshotErrors, boolean][])
            .filter(([, value]) => !value)
            .map(([key]) => key);

        return errors;
    },
    getAddCardModal: (state: RootState) => state.add.snapshot?.modal,
    getSharingContent: (state: RootState) => {
        const { sharingContent = {} } = state.add;
        if (!Object.keys(sharingContent).length) return undefined;
        return sharingContent;
    },
    getDontShowConfirmEditModal: (state: RootState) => {
        const dontShowConfirmEditModal = state.add.dontShowConfirmEditModal || [];
        const userId = userSelectors.userId(state) || "";

        return dontShowConfirmEditModal.includes(userId);
    },
    hasSeenFirstCardReminder: (state: RootState) => {
        const reminders = state.add.firstCardReminderShownTo;
        const userId = userSelectors.userId(state);
        if (!userId) return false;
        return reminders.includes(userId);
    },
    areAnnotationsValid: createSelector(
        [
            (state: RootState) => {
                const {
                    question: { hasAnnotationChanges: questionHasAnnotationChanges = false } = {},
                    answer: { hasAnnotationChanges: answerHasAnnotationChanges = false } = {},
                } = state.add.snapshot || {};

                return questionHasAnnotationChanges || answerHasAnnotationChanges;
            },
            (state: RootState) => state.add.snapshot?.question?.text,
            (state: RootState) => state.add.snapshot?.answer?.text,
            (state: RootState) => state.add.snapshot?.question?.annotation,
            (state: RootState) => state.add.snapshot?.answer?.annotation,
        ],
        (hasChanges, question, answer, qAnnot, aAnnot) => {
            if (!hasChanges) return true;
            return validateAnnotations([question, answer], [qAnnot, aAnnot]);
        }
    ),
    isSaving: (state: RootState) => state.add.isSaving,
};

function setCardContentGenerator(key: "question" | "answer") {
    return function (state: AddState, { payload }: PayloadAction<AddCardContent>) {
        if (!state.snapshot) state.snapshot = { ...defaultSnapshot };
        const prevData = state.snapshot[key] || {};
        state.snapshot[key] = { ...prevData, ...payload };
    };
}

function setCardContentDataGenerator(target: "question" | "answer", key: keyof Required<AddCardContent>) {
    return function (state: AddState, { payload }: PayloadAction<Required<AddCardContent>[typeof key]>) {
        if (!state.snapshot) state.snapshot = { ...defaultSnapshot };
        if (!state.snapshot[target]) state.snapshot[target] = {};
        //@ts-ignore
        state.snapshot[target][key] = payload;

        if (key !== "annotation") return;
        //@ts-ignore
        state.snapshot[target].hasAnnotationChanges = !!payload;
    };
}

function setCardLanguageGenerator(key: "primaryLang" | "secondaryLang") {
    return function (state: AddState, { payload }: PayloadAction<string>) {
        if (!state.snapshot) state.snapshot = { ...defaultSnapshot };
        state.snapshot[key] = payload;
    };
}

function getCardLanguage(target: 0 | 1) {
    return function (state: RootState) {
        const snapshotLang = [state.add.snapshot?.primaryLang || "de", state.add.snapshot?.secondaryLang][target];
        const subjectId = selectors.getAddCardsSubjectId(state);
        if (!subjectId) return snapshotLang;
        const subject = subjectSelectors.getSubjectById(subjectId, "librarySubjects")(state);
        if (!subject) return snapshotLang;
        const {
            subjectContent: { primaryLang, secondaryLang },
        } = subject;

        return [primaryLang, secondaryLang][target];
    };
}
