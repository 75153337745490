// LIBRARIES
import React, { HTMLAttributes, useContext } from "react";

// COMPONENTS
import PhaseSixIcon, { TIconNames } from "../phaseSixIcon/PhaseSixIcon";

// STYLES COMPONENTS
import { ThemeContext } from "styled-components";
import { StyledIcon, InputContainer, StyledInput } from "./styles";

type CustomInputProps = HTMLAttributes<HTMLInputElement> & {
    [key: string]: unknown;
};
export interface TextInputProps extends HTMLAttributes<HTMLElement> {
    value?: string;
    label?: string;
    placeholder?: string;
    onChangeInput: (value: string) => void;
    color?: string;
    type?: string;
    iconName?: TIconNames;
    inputProps?: CustomInputProps;
}

const TextInput: React.FC<TextInputProps> = (props) => {
    const { id, value, iconName, placeholder, color, type, onChangeInput, inputProps, label, ...restProps } = props;
    const theme = useContext(ThemeContext);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeInput(event.target.value);
    };

    let icon = null;
    if (iconName) {
        icon = (
            <StyledIcon>
                <PhaseSixIcon name={iconName} />
            </StyledIcon>
        );
    }

    return (
        <InputContainer {...restProps}>
            {label ? (
                <label
                    id={id}
                    style={{ display: "none" }}
                >
                    {label}
                </label>
            ) : null}
            {icon}
            <StyledInput
                aria-labelledby={id}
                {...inputProps}
                onChange={handleChange}
                value={value}
                placeholder={placeholder ? placeholder : ""}
                color={color ? color : theme.colors.text}
                type={type ? type : "text"}
            />
        </InputContainer>
    );
};

export default TextInput;
