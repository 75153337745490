//DEPENDENCIES
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

//REDUX
import { actions as subjectActions } from "../../redux/subjects/subjectsSlice";
import { actions as modalActions } from "../../redux/modal/modalSlice";
import { selectors as userSelectors } from "../../redux/user/userSlice";

//NETWORK
import { deleteUnit, postUnit, PostUnitProps } from "../../networking/units";

//UTILS
import { generateUuid } from "../../helpers/Id";
import { createSingleUnit, getNextUnitOrder } from "../../helpers/Units";
import { processChanges } from "./useEditDropdown";
import { createSingleSubject, getDefaultSubjectId } from "../../helpers/Subjects";
import { defaultSubjectOrder } from "../../constants/ContentConstants";

//HOOKS
import { useDropdownEditResponsePopup } from "./useDropdownEditResponsePopup";
import { useMultiCreations } from "./useMultiCreations";
import { useMultiUpdates } from "./useMultiUpdates";

//TYPES
import { EditableContentType } from "p6m-viewData";
import { IdToOwnerId } from "p6m-shared";
import { GlobalModalView } from "../../helpers/Modal";
import { EntryChange, SubjectData, SubjectUnitData } from "p6m-subjects";
import { DROPDOWN_CONTENT_TYPES, DROPDOWN_EDIT_TYPES, EDIT_RESPONSE_TYPES } from "./useDropdownEditResponsePopup";

export const useUnitsEdit = ({
    subject,
    subjectId,
    confirmDeletions,
    defaultLabel,
}: {
    subject?: SubjectData;
    subjectId?: string;
    confirmDeletions: boolean;
    defaultLabel: string;
}) => {
    const dispatch = useDispatch();
    const showPopUpMessage = useDropdownEditResponsePopup();
    const userDnsId = useSelector(userSelectors.userId);

    const openDeleteModal = useCallback(
        (deleteData: {
            contentType: EditableContentType;
            contentNames: string[];
            itemIds: IdToOwnerId[];
            deletableCardCount: number;
            selectedSubjectId?: string;
            selectedCardCount?: number;
        }) => {
            dispatch(modalActions.setData(deleteData));
            dispatch(modalActions.setModalView(GlobalModalView.DeleteContent));
        },
        [dispatch]
    );

    const handleSingleCreation = useCallback(
        async ({ subjectId, itemName, index }: { subjectId: string; itemName: string; index: number }) => {
            return createSingleUnit({
                userId: userDnsId,
                subjectId,
                unitId: generateUuid(),
                unitName: itemName,
                unitOrder: getNextUnitOrder(subject?.units, index),
                onError: showPopUpMessage,
            });
        },
        [showPopUpMessage, userDnsId, subject?.units]
    );

    const handleSingleUpdate = useCallback(
        async ({ itemData }: { itemData: SubjectUnitData }) => {
            const unitName = itemData.unitContent.name;
            const postData: PostUnitProps = {
                ownerId: itemData.unitId.ownerId,
                unitId: itemData.unitId.id,
                subjectId: itemData.unitContent.subjectIdToOwner.id,
                name: unitName,
                order: itemData.unitContent.order,
            };

            try {
                const unitUpdateRes = await postUnit(postData);
                return { ...unitUpdateRes.data, name: unitName };
            } catch (e) {
                showPopUpMessage(
                    DROPDOWN_CONTENT_TYPES.UNIT,
                    DROPDOWN_EDIT_TYPES.UPDATE,
                    EDIT_RESPONSE_TYPES.ERROR,
                    unitName
                );
            }
        },
        [showPopUpMessage]
    );

    const handleMultiCreations = useMultiCreations();
    const handleMultiUpdates = useMultiUpdates();
    const handleMultiDeletions = useCallback(
        async (unitsToDelete: SubjectUnitData[]) => {
            const cardAmountToBeDeleted = unitsToDelete.reduce((count, unit) => count + (unit.cardCount || 0), 0);

            if (confirmDeletions && subjectId) {
                openDeleteModal({
                    contentType: "units",
                    contentNames: unitsToDelete.map((unit) => unit.unitContent.name),
                    itemIds: unitsToDelete.map((unit) => unit.unitId),
                    deletableCardCount: cardAmountToBeDeleted,
                    selectedSubjectId: subjectId,
                });
            } else {
                const deletionsRes = await Promise.all(
                    unitsToDelete.map(async (unit) => {
                        const unitName = unit.unitContent.name;
                        try {
                            const unitDeletionRes = await deleteUnit({
                                id: unit.unitId.id,
                                ownerId: unit.unitId.ownerId,
                            });
                            return { ...unitDeletionRes.data, name: unitName };
                        } catch (e) {
                            showPopUpMessage(
                                DROPDOWN_CONTENT_TYPES.UNIT,
                                DROPDOWN_EDIT_TYPES.DELETION,
                                EDIT_RESPONSE_TYPES.ERROR,
                                unitName
                            );
                        }
                    })
                );

                const failedDeletions: string[] =
                    deletionsRes?.filter((item) => item.httpCode !== 200).map((item) => item.name) || [];
                if (failedDeletions.length) {
                    showPopUpMessage(
                        DROPDOWN_CONTENT_TYPES.UNIT,
                        DROPDOWN_EDIT_TYPES.DELETION,
                        EDIT_RESPONSE_TYPES.ERROR,
                        failedDeletions.join(", ")
                    );
                } else {
                    showPopUpMessage(
                        DROPDOWN_CONTENT_TYPES.UNIT,
                        DROPDOWN_EDIT_TYPES.DELETION,
                        EDIT_RESPONSE_TYPES.SUCCESS,
                        deletionsRes.map((item) => item.name).join(", ")
                    );
                }
            }
        },
        [openDeleteModal, showPopUpMessage, confirmDeletions, subjectId]
    );

    const saveUnitsEdit = useCallback(
        async (changes: EntryChange[]) => {
            let subjectIdToUse = subjectId;
            if (!subjectId || !userDnsId) {
                return;
            } else if (subjectId === "default") {
                const defaultSubjectId = getDefaultSubjectId(userDnsId);
                const res = await createSingleSubject({
                    userId: userDnsId,
                    subjectName: defaultLabel,
                    subjectId: defaultSubjectId,
                    subjectOrder: defaultSubjectOrder,
                    withDefaultUnit: true,
                    unitName: defaultLabel,
                    onError: showPopUpMessage,
                });
                if (res?.httpCode === 200) {
                    subjectIdToUse = defaultSubjectId;
                }
            }

            const findUnit = ({ itemId }: { itemId: string }): SubjectUnitData | undefined =>
                subject?.units?.find((unit) => unit.unitId.id === itemId);

            const { itemsToCreate, itemsToUpdate } = await processChanges<SubjectUnitData>({
                subjectId: subjectIdToUse!,
                changes,
                findItemFn: findUnit,
                handleCreationFn: handleSingleCreation,
                handleUpdateFn: handleSingleUpdate,
                handleDeletionsFn: handleMultiDeletions,
            });

            await handleMultiCreations(itemsToCreate, DROPDOWN_CONTENT_TYPES.UNIT);
            await handleMultiUpdates(itemsToUpdate, DROPDOWN_CONTENT_TYPES.UNIT);

            dispatch(subjectActions.loadSubjectUnits({ subjectId, target: "librarySubjects" }));
        },
        [dispatch, processChanges, showPopUpMessage, subjectId, subject]
    );

    return saveUnitsEdit;
};
