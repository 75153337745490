// REACT
import React, { FunctionComponent, memo } from "react";

// COMPONENTS
import PhaseSixIcon from "../../phaseSixIcon/PhaseSixIcon";
import { LanguageSelect, Props as LangComponentProps } from "../LanguageSelect";

// STYLED COMPONENTS
import { Wrapper, CharsContainer, Char, FooterContainer, CaseToggle, CharButton, CloseButton } from "./styles";

type Props = {
    chars: string[];
    isUppercase: boolean;
    onClose: () => void;
    onCaseToggle: () => void;
    onCharSelect: (selectedChar: string) => void;
    handleKeyDown: (event: React.KeyboardEvent<HTMLUListElement>) => void;
} & LangComponentProps;

const SpecialCharMenu: FunctionComponent<Props> = (props) => {
    const { chars, lang, langList, isUppercase, onLangSelect, onClose, onCaseToggle, onCharSelect, handleKeyDown } =
        props;

    return (
        <Wrapper>
            <CharsContainer onKeyDown={handleKeyDown}>
                {chars.map((char: string, i: number) => (
                    <Char key={char + i}>
                        <CharButton onClick={() => onCharSelect(char)}>{char}</CharButton>
                    </Char>
                ))}
            </CharsContainer>
            <FooterContainer>
                <CaseToggle
                    onClick={() => onCaseToggle()}
                    active={isUppercase}
                >
                    <PhaseSixIcon name="uppercase" />
                </CaseToggle>
                {!!langList && (
                    <LanguageSelect
                        lang={lang}
                        langList={langList}
                        onLangSelect={onLangSelect}
                    />
                )}
                <CloseButton onClick={onClose}>
                    <PhaseSixIcon name="close" />
                </CloseButton>
            </FooterContainer>
        </Wrapper>
    );
};

export default memo(SpecialCharMenu);
