import styled from "styled-components";

export const Wrapper = styled.div`
    display: block;
    color: ${({ theme: { colors } }) => colors.text};
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    border-top: 1px solid ${({ theme: { colors } }) => colors.border};
    border-radius: 16px;
`;

export const CharsContainer = styled.ul`
    list-style: none;
    display: block;
    max-height: 64px;
    margin: 0;
    padding: ${({ theme: { base } }) => base.spacingInContent} 0;
    font-size: ${({ theme: { base } }) => base.sizeMedium};
    line-height: 25px;
    vertical-align: middle;
    overflow-y: auto;
`;

export const Char = styled.li`
    display: inline-block;
    width: 25px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: inherit;
    line-height: inherit;
    vertical-align: middle;
`;

const BaseButton = styled.button`
    border: 0;
    background: 0;
    cursor: pointer;
`;

export const CharButton = styled(BaseButton)`
    transition: ${({ theme: { base } }) => base.transition};
    border-radius: ${({ theme: { base } }) => base.borderRadiusSmall};

    &:hover {
        background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    }
`;

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${({ theme: { base } }) => base.spacingInContent} 0;
    font-size: ${({ theme: { base } }) => base.size};
    vertical-align: middle;
    // color: ${({ theme: { colors } }) => colors.text};

    & > * {
        margin: 0 ${({ theme: { base } }) => base.spacingInContent};
    }
`;

export const CaseToggle = styled(BaseButton)<{ active: boolean }>`
    padding: 0;
    ${({ active, theme: { colors } }) => (active ? `color: ${colors.primary}` : "")}
`;

export const CloseButton = styled(BaseButton)`
    padding: 0;
    color: ${({ theme: { colors } }) => colors.primary};
`;
