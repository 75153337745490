// LIBRARIES
import React from "react";

import DeleteMediaModal from "./DeleteMediaModal";
import { actions as modalActions, selectors as modalSelectors } from "../../../redux/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { GlobalModalView } from "../../../helpers/Modal";

const DeleteMediaModalWrapper: React.FC = () => {
    const data: {
        files: { changeImages: string[]; changeAudios: string[] };
        imageAction: any;
        audioAction: any;
    } = useSelector(modalSelectors.data);

    const dispatch = useDispatch();

    const close = () => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
    };

    const deleteMedia = () => {
        dispatch(data.imageAction(data.files.changeImages));
        dispatch(data.audioAction(data.files.changeAudios));
    };

    const onDeleteMedia = () => {
        deleteMedia();
        close();
    };

    return (
        <DeleteMediaModal
            close={close}
            onDeleteMedia={onDeleteMedia}
        />
    );
};

export default DeleteMediaModalWrapper;
