//LIBRARIES
import { AxiosResponse } from "axios";

//TYPES
import { IUserMetadata, IUserMetadataResponse } from "p6m-user";
import { IResponse } from "p6m-response";

export const getRandomUserName = () => {
    const listOfNames = [
        "Secret Spider",
        "Private Panther",
        "Anonymous Alpaca",
        "Hidden Hamster",
        "Invisible Iltis",
        "Undercover Unicorn",
        "Furtive Fox",
        "Stealthy Salamander",
        "Masked Mouse",
        "Sneaky Snake",
        "Confidential Cat",
        "Shrouded Scorpion",
    ];
    return listOfNames[Math.floor(Math.random() * listOfNames.length)];
};

export const convertMetadataValuesToBoolean = (metadataResponse: IUserMetadataResponse): IUserMetadata => {
    const phasesShownData: { [key: number]: boolean } = {};
    for (let key in metadataResponse.phasesShownData) {
        phasesShownData[key] = !!metadataResponse.phasesShownData[key];
    }

    return {
        betaTestWindowShown: !!metadataResponse.betaTestWindowShown,
        hasFirstPractice: !!metadataResponse.hasFirstPractice,
        lastTimeGDPRChecked: metadataResponse.lastTimeGDPRChecked,
        leaderBoardWindowShown: !!metadataResponse.leaderBoardWindowShown,
        phasesShownData: phasesShownData,
        schoolDashboardSwitchAvailable: !!metadataResponse.schoolDashboardSwitchAvailable,
        visitedReactWebApp: !!metadataResponse.visitedReactWebApp,
        enableGrammarTutorPromotion:
            metadataResponse.enableGrammarTutorPromotion === undefined ||
            !!metadataResponse.enableGrammarTutorPromotion,
    };
};

export const goToOldAppVersion = (userId: string, jossoSessionId: string): void => {
    window.location.replace(process.env.REACT_APP_OLD_APP_URL + "#/sso/" + userId + "/" + jossoSessionId);
};

export const deconstructUserMetaDataFromAPIResponse = (
    response: AxiosResponse<
        IResponse<{
            userData: IUserMetadataResponse;
        }>
    >
) => response.data.replyContent.userData;
