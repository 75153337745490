// LIBRARIES
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Redirect, useHistory, useParams } from "react-router-dom";
import { debounce } from "lodash";
import { useT } from "@transifex/react";

// REDUX
import { actions as subjectsActions } from "../../redux/subjects/subjectsSlice";
import { actions as userActions, selectors as userSelectors } from "../../redux/user/userSlice";
import { actions as responseActions } from "../../redux/response/responseSlice";

// COMPONENTS
import Manage from "./Manage";

// UTILS
import { ResponseType } from "../../constants/ResponseConstants";

// HOOKS
import { useAppVersionCheck } from "../../hooks/useAppVersionCheck";
import { useEditDropdown } from "../../hooks/dropdown/useEditDropdown";
import { useFirstPracticeResult } from "../../hooks/useFirstPracticeResult";

const ManageWrapper: React.FC = () => {
    const t = useT();
    const history = useHistory();
    const dispatch = useDispatch();
    const { subjectId, searchText } = useParams();

    const {
        subjects: { dropdownSubjectLabel, dropdownSubjectItems, allSubjects, selectedSubject, saveSubjectsEdit },
        units: { dropdownUnitLabel, dropdownUnitItems, saveUnitsEdit },
    } = useEditDropdown({ subjectId });

    const user = useSelector(userSelectors.user);
    const userPreferences = useSelector(userSelectors.userPreferences);
    const isTeacher = useSelector(userSelectors.isTeacher);
    const { phases } = useSelector(userSelectors.userPreferences);
    const isFirstPracticeFinished = useFirstPracticeResult();

    const hasFirstPractice = isTeacher || isFirstPracticeFinished;
    const isLibraryAccessDisabled = userPreferences.parentSettings
        ? !userPreferences.parentSettings.libraryAccess
        : false;

    const t_libraryAccessBlockedByAdmin = t("The group admin has blocked the access to the library.", {
        _tags: "response,error,library",
    });

    const setResponse = useCallback(
        (responseType: ResponseType, text: string) => {
            dispatch(responseActions.showResponse({ type: responseType, text: [text] }));
        },
        [dispatch]
    );

    // debounce switching subject to prevent a back and forth switching when moving cards
    const onSubjectSelect = useCallback(
        debounce((passedSubjectId: string | undefined, passedSearchValue: string) => {
            if (passedSubjectId) {
                let path: string = "/manage/" + passedSubjectId;
                if (passedSearchValue.length > 0) path += "/" + passedSearchValue;
                history.push(path);
            } else {
                // use generatePath to remove params
                history.push({ pathname: generatePath("/manage") });
            }
        }, 500),
        [history]
    );

    useAppVersionCheck();

    useEffect(() => {
        if (!hasFirstPractice || isLibraryAccessDisabled) {
            if (isLibraryAccessDisabled) {
                setResponse("ERROR", t_libraryAccessBlockedByAdmin);
            }
            history.push("/home");
        }
    }, [hasFirstPractice, isLibraryAccessDisabled, history, setResponse, t_libraryAccessBlockedByAdmin]);

    useEffect(() => {
        dispatch(subjectsActions.loadLibrarySubjects());
        dispatch(subjectsActions.loadFamilySubjects());
    }, [dispatch, subjectId]);

    useEffect(() => {
        if (!phases || phases.phaseList.length === 0) {
            dispatch(userActions.refreshPreferencesData(user.userDnsId || ""));
        }
    }, [dispatch, phases, user]);

    if (allSubjects.length && selectedSubject) {
        return (
            <Manage
                user={user}
                subjectId={subjectId}
                subject={selectedSubject}
                initialSearchText={searchText}
                setResponse={setResponse}
                onSubjectSelect={onSubjectSelect}
                subjectLabel={dropdownSubjectLabel}
                unitLabel={dropdownUnitLabel}
                subjectOptions={dropdownSubjectItems}
                unitOptions={dropdownUnitItems}
                availablePhases={phases?.phaseList.map((_, i) => i) || []}
                saveSubjectEdit={saveSubjectsEdit}
                saveUnitEdit={saveUnitsEdit}
            />
        );
    } else {
        return <Redirect to="/manage" />;
    }
};

export default ManageWrapper;
