import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { DispatchAction } from "p6m-warnings";

const warningsLimit: number = parseInt(process.env.REACT_APP_WARNINGS_LIMIT || "4");
const warningsStartCount: number = parseInt(process.env.REACT_APP_WARNINGS_START_COUNT || "200");

type WarningsSlice = {
    ready: boolean;
    count: number;
    startCount: number;
};

const initialState: WarningsSlice = {
    ready: false,
    count: warningsLimit,
    startCount: warningsStartCount,
};

export const warningsSlice = createSlice({
    name: "warnings",
    initialState,
    reducers: {
        fetchWarnings: () => {},
        clearWarnings: (state) => {
            state.ready = initialState.ready;
            state.count = initialState.count;
            state.startCount = initialState.startCount;
        },
        setWarnings: (state, { payload }: PayloadAction<Partial<WarningsSlice>>) => {
            const { count, startCount, ready } = payload;
            if (ready !== undefined) state.ready = ready;
            if (count) state.count = state.count - count;
            if (startCount) state.startCount = state.startCount - startCount;
        },
        startWarnings: () => {},
        stopWarnings: () => {},
        doAction: (_state, _action: PayloadAction<DispatchAction>) => {},
    },
});

export const { actions, reducer } = warningsSlice;

export const selectors = {
    ready: (state: RootState) => state.warnings.ready,
    count: (state: RootState) => state.warnings.count,
    startCount: (state: RootState) => state.warnings.startCount,
    isUserCheater: (state: RootState) => state.warnings.count <= 0,
};
