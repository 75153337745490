// REACT
import React, { FunctionComponent, memo } from "react";

// COMPONENTS
import Modal from "../../../basic/modal/Modal";
import Header from "./ModalHeader/ModalHeader";
import Footer from "./ModalFooter/ModalFooter";
import SubjectCreate, { Props as SubjProps } from "./ModalCreation/SubjectCreate";
import UnitCreate, { Props as UnitProps } from "./ModalCreation/UnitCreate";
import SubjectItem, { Props as SubjItemProps } from "./SubjectItem/SubjectItem";
import DefaultSubjectItem, { Props as DefaultSubjItemProps } from "./SubjectItem/DefaultSubjectItem";
import UnitItem, { Props as UnitItemProps } from "./UnitItem/UnitItem";
import DefaultUnitItem, { Props as DefaultUnitItemProps } from "./UnitItem/DefaultUnitItem";
import { DefaultFocus } from "../../../basic/defaultFocus/DefaultFocus";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

// TYPES
import { SubjectData, UnitsList } from "p6m-subjects";

// UTILS
import { sortUnitsCallback, isDefaultUnit } from "../../../../helpers/Units";

// STYLED COMPONENTS
import { Content, HeaderWrapper, FooterWrapper, CloseIcon } from "./styles";

export type Props = {
    subjects?: SubjectData[];
    units?: UnitsList;
    type: "subject" | "unit" | "share";
    active: boolean;
    showCreationSidebar: boolean;
    userDnsId?: string;
    editItem?: string;
    setEditItem: (item?: string) => void;
    goBack: () => void;
    onSubjectSelect: SubjItemProps["onSelect"];
    onUnitSelect: UnitItemProps["onSelect"];
    afterClose: () => void;
    onAddClick: () => void;
    onCreationClose: () => void;
    onSubjectCreate: SubjProps["onSave"];
    onUnitCreate: UnitProps["onSave"];
    onSubjectEdit: SubjItemProps["onEdit"];
    onUnitEdit: UnitItemProps["onEdit"];
    onUnitDelete: UnitItemProps["onDelete"];
    onShare?: () => void;
    onDefaultUnitSelect: DefaultUnitItemProps["onSelect"];
    onDefaultSubjectSelect: DefaultSubjItemProps["onSelect"];
};

const ContentModal: FunctionComponent<Props> = (props) => {
    const {
        subjects = [],
        units = [],
        type,
        active,
        showCreationSidebar,
        userDnsId,
        editItem,
        setEditItem,
        goBack,
        onSubjectSelect,
        onUnitSelect,
        afterClose,
        onAddClick,
        onCreationClose,
        onSubjectCreate,
        onUnitCreate,
        onSubjectEdit,
        onUnitEdit,
        onUnitDelete,
        onShare,
        onDefaultUnitSelect,
        onDefaultSubjectSelect,
    } = props;

    if (!active) {
        return null;
    }

    return (
        <Modal
            active={active}
            headerComponent={({ close }) => (
                <HeaderWrapper>
                    <Header
                        type={type}
                        goBack={goBack}
                    />
                    <CloseIcon onClick={close}>
                        <PhaseSixIcon name="close" />
                    </CloseIcon>
                </HeaderWrapper>
            )}
            footerComponent={false}
            destroyOnClose={true}
            afterClose={afterClose}
        >
            <Content
                $edit={showCreationSidebar}
                tabIndex={-1}
            >
                <DefaultFocus />
                {(type === "subject" &&
                    subjects
                        .map((subject) => {
                            const {
                                subjectMetadata: {
                                    subjectIdToOwner: { id },
                                },
                            } = subject;
                            return (
                                <SubjectItem
                                    key={id}
                                    edit={id === editItem}
                                    subject={subject}
                                    userDnsId={userDnsId}
                                    setEdit={(state: boolean) => setEditItem(state ? id : undefined)}
                                    onEdit={onSubjectEdit}
                                    onSelect={onSubjectSelect}
                                />
                            );
                        })
                        .concat(
                            subjects.length ? (
                                []
                            ) : (
                                <DefaultSubjectItem
                                    key="default"
                                    onSelect={onDefaultSubjectSelect}
                                />
                            )
                        )) ||
                    (type === "unit" &&
                        [...units]
                            .sort(sortUnitsCallback)
                            .map((unit) => {
                                const {
                                    unitId: { id },
                                } = unit;
                                return (
                                    <UnitItem
                                        key={id}
                                        edit={id === editItem}
                                        unit={unit}
                                        userDnsId={userDnsId}
                                        setEdit={(state: boolean) => setEditItem(state ? id : undefined)}
                                        onEdit={onUnitEdit}
                                        onSelect={onUnitSelect}
                                        onDelete={onUnitDelete}
                                    />
                                );
                            })
                            .concat(
                                hasDefaultUnit(units) ? (
                                    []
                                ) : (
                                    <DefaultUnitItem
                                        key="default"
                                        onSelect={onDefaultUnitSelect}
                                    />
                                )
                            ))}
                {
                    // --- We don't want to remove it yet permanently (including all relevant logic and functionalities in parent components), just in case we might need it again in the future! ----
                    /* <ShareContent /> */
                }
            </Content>
            <FooterWrapper>
                <Footer
                    type={type}
                    onClick={onAddClick}
                    onShare={onShare}
                />
            </FooterWrapper>
            {!!showCreationSidebar &&
                ((type === "subject" && (
                    <SubjectCreate
                        afterClose={onCreationClose}
                        onSave={onSubjectCreate}
                    />
                )) ||
                    (type === "unit" && (
                        <UnitCreate
                            afterClose={onCreationClose}
                            onSave={onUnitCreate}
                        />
                    )))}
        </Modal>
    );
};

export default memo(ContentModal);

function hasDefaultUnit(unitsList: UnitsList) {
    return !!unitsList.find(function (unit) {
        return isDefaultUnit(unit);
    });
}
