// LIBRARIES
import React from "react";

import ResetSubjectCardsModal from "./ResetSubjectCardsModal";
import { actions as modalActions, selectors as modalSelectors } from "../../../redux/modal/modalSlice";
import { actions as subjectActions } from "../../../redux/subjects/subjectsSlice";
import { useDispatch, useSelector } from "react-redux";
import { SubjectData } from "p6m-subjects";
import { GlobalModalView } from "../../../helpers/Modal";

const ResetSubjectCardsModalWrapper: React.FC = () => {
    const dispatch = useDispatch();
    const data: { subject: SubjectData } = useSelector(modalSelectors.data);

    const close = () => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
    };
    const reset = () => {
        dispatch(subjectActions.resetSubject(data.subject.subjectMetadata.subjectIdToOwner.id));
        close();
    };

    return (
        <ResetSubjectCardsModal
            subject={data.subject}
            close={close}
            reset={reset}
        />
    );
};

export default ResetSubjectCardsModalWrapper;
