import axios from "axios";
import authInterceptor from "./interceptors/authInterceptor";
import { store } from "../store/store";
import { actions } from "../redux/appStatus/appStatusSlice";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API_URL;

axios.interceptors.request.use(authInterceptor);

// handle connection status
axios.interceptors.response.use(
    (response) => {
        // turn maintenance off, if request succeeds
        const isInMaintenance = store.getState().appStatus.showMaintenancePage;
        if (isInMaintenance) store.dispatch(actions.setMaintenance(false));
        return response;
    },
    (_error) => {
        // turn maintenance on, if request fails
        store.dispatch(actions.setMaintenance(true));
    }
);
