//DEPENDENCIES
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { groupBy } from "lodash";
import { UpdateTestProps } from "../../networking/tests";

//REDUX
import { RootState } from "../../store/store";

//TYPES
import { TestListItemInfo } from "p6m-tests";
import { SubjectData } from "p6m-subjects";
import { ActivationMetaData, SharedTestMetaData } from "p6m-tests";

interface TestState {
    subject?: SubjectData;
    sharedTestMetaData: null | SharedTestMetaData;
    activationMetaData: null | ActivationMetaData;
    allTestsBySubjectId: Record<string, TestListItemInfo[]>;
    ownTestsBySubjectId: Record<string, TestListItemInfo[]>;
    sharedTestsBySubjectId: Record<string, TestListItemInfo[]>;
}

const initialState: TestState = {
    subject: undefined,
    sharedTestMetaData: null,
    activationMetaData: null,
    allTestsBySubjectId: {},
    ownTestsBySubjectId: {},
    sharedTestsBySubjectId: {},
};

export interface UpdateTestPayload {
    testId: string;
    data: UpdateTestProps;
}

export const testsSlice = createSlice({
    name: "tests",
    initialState,
    reducers: {
        setSubject: (state, action: PayloadAction<SubjectData | undefined>) => {
            state.subject = action.payload;
        },
        setSharedTestMetaData: (state, action: PayloadAction<SharedTestMetaData | null>) => {
            state.sharedTestMetaData = action.payload;
        },
        setActivationMetaData: (state, action: PayloadAction<ActivationMetaData | null>) => {
            state.activationMetaData = action.payload;
        },
        fetchTests: () => {},
        updateTest: (_state, _action: PayloadAction<UpdateTestPayload>) => {},
        deleteTest: (_state, _action: PayloadAction<string>) => {},
        setAllTests: (state, action: PayloadAction<TestListItemInfo[]>) => {
            state.allTestsBySubjectId = groupBy(action.payload, (item) => item.subjectContentId.id);
        },
        setOwnTests: (state, action: PayloadAction<TestListItemInfo[]>) => {
            state.ownTestsBySubjectId = groupBy(action.payload, (item) => item.subjectContentId.id);
        },
        setSharedTests: (state, action: PayloadAction<TestListItemInfo[]>) => {
            state.sharedTestsBySubjectId = groupBy(action.payload, (item) => item.subjectContentId.id);
        },
        fetchSharedTestFromMetadata: () => {},
    },
});

/* EXPORTS */
export const { actions, reducer } = testsSlice;

export const selectors = {
    subject: (state: RootState) => state.tests.subject,
    sharedTestMetaData: (state: RootState) => state.tests.sharedTestMetaData,
    activationMetaData: (state: RootState) => state.tests.activationMetaData,
    allTestsBySubjectId: (subjectId: string) => (state: RootState) => state.tests.allTestsBySubjectId[subjectId] ?? [],
    ownTestsBySubjectId: (subjectId: string) => (state: RootState) => state.tests.ownTestsBySubjectId[subjectId] ?? [],
    sharedTestsBySubjectId: (subjectId: string) => (state: RootState) =>
        state.tests.sharedTestsBySubjectId[subjectId] ?? [],
};
