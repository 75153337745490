import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { actions, subjectListSelectors } from "./librarySlice";
import { actions as appStatusActions } from "../appStatus/appStatusSlice";
import { AxiosResponse } from "axios";
import { IResponse } from "p6m-response";
import { getSubjectCardsCount, getSubjectIds, getSubjectListData } from "../../networking/subjects";
import { GetSubjectsBulkResponse, LibrarySubject, SubjectUnitCard } from "p6m-subjects";
import { getDefaultLanguageCoverImage, getPublisherSubjectCover } from "../../helpers/Subjects";
import { PayloadAction } from "@reduxjs/toolkit";
import { getCardsFromSubject } from "../../networking/cards";
import { CardsResponseData } from "p6m-cards";

function* loadSubjectListSaga() {
    yield put(appStatusActions.setLoading(true));

    try {
        const subjectIdsResponse: AxiosResponse<IResponse<{ ids: Array<{ id: string; ownerId: string }> }>> =
            yield call(getSubjectIds);

        const subjectIds = subjectIdsResponse.data.replyContent.ids;

        const responses = yield all([
            call(getSubjectListData, subjectIds),
            ...subjectIds.map((item) => call(getSubjectCardsCount, item.id)),
        ]);

        const [subjectsDataResponse, ...cardCountResponses] = responses;
        const totalLibraryCardsCount = cardCountResponses.reduce(
            (acc: number, response: AxiosResponse<IResponse<{ cardCount: number }>>) => {
                return acc + response.data.replyContent.cardCount;
            },
            0
        );

        const subjects = (
            subjectsDataResponse as AxiosResponse<IResponse<GetSubjectsBulkResponse>>
        ).data.replyContent.data.map((item, i) => {
            let coverImg = getDefaultLanguageCoverImage(item.data.secondaryLang);
            if ("imageId" in item.data) {
                coverImg = getPublisherSubjectCover(item.data.imageId);
            }

            return {
                id: item.objectId.id,
                name: item.data.name,
                primaryLang: item.data.primaryLang,
                secondaryLang: item.data.secondaryLang,
                totalCards: cardCountResponses[i].data.replyContent.cardCount,
                ownerId: item.objectId.ownerId,
                cover: coverImg,
            };
        });

        const sortedSubjects = subjects.sort((a, b) => {
            if (b.name > a.name) {
                return -1;
            } else if (b.name < a.name) {
                return 1;
            }

            return 0;
        });

        yield put(actions.setSubjectList({ subjects: sortedSubjects, totalCards: totalLibraryCardsCount }));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* searchSubjectListCardsSaga(action: PayloadAction<string>) {
    if (action.payload.length === 0) {
        yield put(actions.resetSubjectListSearch());
    } else {
        yield put(appStatusActions.setLoading(true));
        try {
            const subjects: LibrarySubject[] = yield select(subjectListSelectors.getSubjects);
            const filteredCards: Record<string, SubjectUnitCard[]> = {};

            const response: AxiosResponse<IResponse<CardsResponseData>> = yield call(getCardsFromSubject, {
                searchString: action.payload,
                noLimit: true,
                limitCardsPerSubject: 5,
            });

            subjects.forEach((subject) => {
                filteredCards[subject.id] = response.data.replyContent.cards.filter(
                    (card) => card.subjectIdToOwner.id === subject.id
                );
            });

            yield put(
                actions.setFilteredSubjectListCards({
                    cards: filteredCards,
                    totalCards: response.data.replyContent.cards.length,
                })
            );
        } catch (err) {
            console.log(err);
        } finally {
            yield put(appStatusActions.setLoading(false));
        }
    }
}

export function* librarySaga() {
    yield takeEvery(actions.loadSubjectList, loadSubjectListSaga);
    yield takeEvery(actions.searchSubjectListCards, searchSubjectListCardsSaga);
}
