// LIBRARIES
import React from "react";

import AddSubjectModal from "./AddSubjectModal";
import { getAddSubjectUrl } from "../../../helpers/Url";
import { actions as modalActions } from "../../../redux/modal/modalSlice";
import { selectors as userSelectors } from "../../../redux/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { GlobalModalView } from "../../../helpers/Modal";

const ShareModalWrapper: React.FC = () => {
    const jossoId = useSelector(userSelectors.jossoId);
    const addUrl = getAddSubjectUrl(jossoId || "");

    const dispatch = useDispatch();

    const close = () => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
    };

    return (
        <AddSubjectModal
            url={addUrl}
            close={close}
        />
    );
};

export default ShareModalWrapper;
