//LIBRARES
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//REDUX
import { RootState } from "../../store/store";

//TYPES
import { LoginDataType } from "./loginSaga";
import { JossoUpdateResponseType } from "p6m-response";

/* LOGIN WARNINGS */
export enum LoginWarnings {
    None,
    Agreement,
    LoginDataChanged,
}

export interface UserCredentials {
    username: string;
    password: string;
    jossoSessionId: string;
    remember: boolean;
}

interface LoginState {
    warning: LoginWarnings;
    credentials: Partial<UserCredentials>;
}
const initialState: LoginState = {
    warning: LoginWarnings.None,
    credentials: {
        jossoSessionId: "",
        remember: false,
    },
};
export const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setWarning: (state, action: PayloadAction<LoginWarnings>) => {
            state.warning = action.payload;
        },
        unsetWarning: (state) => {
            state.warning = LoginWarnings.None;
        },
        setCredentials: (state, action: PayloadAction<Partial<UserCredentials>>) => {
            state.credentials = action.payload;
        },
        login: (_state, _action: PayloadAction<LoginDataType>) => {},
        loginWithSSOData: (_state, _action: PayloadAction<{ data: JossoUpdateResponseType; history: any }>) => {},
    },
});
/* EXPORTS */
export const { actions, reducer } = loginSlice;

export const selectors = {
    warning: (state: RootState) => state.login.warning,
    credentials: (state: RootState) => state.login.credentials,
};
