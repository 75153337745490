// REACT
import React, {
    FunctionComponent,
    PropsWithChildren,
    useState,
    useEffect,
    useCallback,
    Children,
    useContext,
} from "react";
import FocusLock from "react-focus-lock";

// COMPONENTS
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";
import Component, { Props as ComponentProps } from "./SpecialCharSelect";
import { SpecialCharMenu } from "./SpecialCharMenu";

// UTILS
import { PracticeContext } from "../../../context/practice";

// STYLES
import { CloseButton } from "./styles";

export type Props = {
    isOpen?: boolean;
    lang?: string;
    onCharSelect: (selectedChar: string) => void;
} & Partial<Omit<ComponentProps, "isOpen" | "menu">>;

export const SpecialCharSelect: FunctionComponent<PropsWithChildren<Props>> = (props) => {
    const practiceContext = useContext(PracticeContext);
    const { children, lang, isOpen: propsIsOpen = false, onCharSelect, ...restComponentWrapperProps } = props;
    const [isOpen, setOpen] = useState(propsIsOpen);
    const [selectedLang, setSelectedLang] = useState<string | undefined>();
    const [focusLockEnabled, setFocusLockEnabled] = useState<boolean>(false); //?

    useEffect(() => {
        if (lang === selectedLang) return;
        setSelectedLang(lang);
    }, [propsIsOpen]);

    useEffect(() => {
        if (typeof propsIsOpen === "undefined") return;
        setOpen(propsIsOpen);
    }, [propsIsOpen]);

    const openCallback = useCallback(() => {
        setOpen(true);
        practiceContext.setIsCharMenuOpen(true);
    }, []);

    const closeCallback = useCallback(() => {
        setOpen(false);
        practiceContext.setIsCharMenuOpen(false);
        practiceContext.setSelectedCharLanguage(undefined);
    }, []);

    const handleCharSelect = (selectedChar: string) => {
        onCharSelect(selectedChar);
        setFocusLockEnabled(false);
    };

    return (
        <Component
            isOpen={isOpen}
            menu={
                <FocusLock disabled={!isOpen || !focusLockEnabled}>
                    <SpecialCharMenu
                        setFocusLock={setFocusLockEnabled}
                        onClose={closeCallback}
                        onCharSelect={handleCharSelect}
                        lang={practiceContext.selectedCharLanguage || lang}
                        onChangeLang={(lang: string) => {
                            practiceContext.setSelectedCharLanguage(lang);
                            setSelectedLang(lang);
                        }}
                    />
                </FocusLock>
            }
            {...restComponentWrapperProps}
        >
            {!!children ? (
                Children.only(children)
            ) : (
                <CloseButton onClick={openCallback}>
                    <PhaseSixIcon name="special-character" />
                </CloseButton>
            )}
        </Component>
    );
};
