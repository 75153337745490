//DEPENDENCIES
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

//REDUX
import { actions as subjectActions } from "../../redux/subjects/subjectsSlice";
import { actions as modalActions } from "../../redux/modal/modalSlice";
import { selectors as userSelectors } from "../../redux/user/userSlice";

//NETWORK
import { deleteSubject, postSubject } from "../../networking/subjects";

//UTILS
import { createSingleSubject, generateSubjectTemplate } from "../../helpers/Subjects";
import { processChanges } from "./useEditDropdown";

//HOOKS
import { useDropdownEditResponsePopup } from "./useDropdownEditResponsePopup";
import { useMultiCreations } from "./useMultiCreations";
import { useMultiUpdates } from "./useMultiUpdates";

//TYPES
import { EditableContentType } from "p6m-viewData";
import { IdToOwnerId } from "p6m-shared";
import { GlobalModalView } from "../../helpers/Modal";
import { EntryChange, SubjectData } from "p6m-subjects";
import { DROPDOWN_CONTENT_TYPES, DROPDOWN_EDIT_TYPES, EDIT_RESPONSE_TYPES } from "./useDropdownEditResponsePopup";

export const useSubjectsEdit = ({
    subjects,
    subjectId,
    confirmDeletions,
    defaultLabel,
}: {
    subjects: SubjectData[];
    subjectId?: string;
    confirmDeletions: boolean;
    defaultLabel: string;
}) => {
    const dispatch = useDispatch();
    const showPopUpMessage = useDropdownEditResponsePopup();
    const userDnsId = useSelector(userSelectors.userId);

    const openDeleteModal = useCallback(
        (deleteData: {
            contentType: EditableContentType;
            contentNames: string[];
            itemIds: IdToOwnerId[];
            deletableCardCount: number;
            selectedSubjectId?: string;
            selectedCardCount?: number;
        }) => {
            dispatch(modalActions.setData(deleteData));
            dispatch(modalActions.setModalView(GlobalModalView.DeleteContent));
        },
        [dispatch]
    );

    const handleSingleCreation = useCallback(
        async ({ itemName }: { itemName: string }) => {
            return createSingleSubject({
                userId: userDnsId,
                subjectName: itemName,
                withDefaultUnit: true,
                unitName: defaultLabel,
                onError: showPopUpMessage,
            });
        },
        [userDnsId, defaultLabel, showPopUpMessage]
    );

    const handleSingleUpdate = useCallback(
        async ({ itemId, itemData }: { itemId: string; itemData: SubjectData }) => {
            const subjectName = itemData.subjectContent.name;
            try {
                const subjectUpdateRes = await postSubject(
                    generateSubjectTemplate({
                        subjectId: itemId,
                        creatorId: itemData.subjectMetadata.subjectIdToOwner.ownerId,
                        name: subjectName,
                    })
                );
                return { ...subjectUpdateRes.data, name: subjectName };
            } catch (e) {
                showPopUpMessage(
                    DROPDOWN_CONTENT_TYPES.SUBJECT,
                    DROPDOWN_EDIT_TYPES.UPDATE,
                    EDIT_RESPONSE_TYPES.ERROR,
                    subjectName
                );
            }
        },
        [showPopUpMessage]
    );

    const handleMultiCreations = useMultiCreations();
    const handleMultiUpdates = useMultiUpdates();
    const handleMultiDeletions = useCallback(
        async (subjectsToDelete: SubjectData[]) => {
            const contentNames = subjectsToDelete.map((subject) => subject.subjectContent.name);
            const itemIds = subjectsToDelete.map((subject) => subject.subjectMetadata.subjectIdToOwner);
            const cardAmountToBeDeleted = subjectsToDelete.reduce(
                (count, subject) => count + (subject.allCardsCount || 0),
                0
            );
            if (confirmDeletions) {
                openDeleteModal({
                    contentType: "subjects",
                    contentNames,
                    itemIds,
                    deletableCardCount: cardAmountToBeDeleted,
                    selectedSubjectId: subjectId,
                });
            } else {
                try {
                    const deletionsRes = await Promise.all(
                        subjectsToDelete.map(async (s) => {
                            const subjectName = s.subjectContent.name;
                            try {
                                const subjectDeletionRes = await deleteSubject({
                                    id: s.subjectMetadata.subjectIdToOwner.id,
                                    ownerId: s.subjectMetadata.subjectIdToOwner.ownerId,
                                });
                                return { ...subjectDeletionRes.data, name: subjectName };
                            } catch (e) {
                                showPopUpMessage(
                                    DROPDOWN_CONTENT_TYPES.SUBJECT,
                                    DROPDOWN_EDIT_TYPES.DELETION,
                                    EDIT_RESPONSE_TYPES.ERROR,
                                    subjectName
                                );
                            }
                        })
                    );

                    const failedDeletions: string[] =
                        deletionsRes?.filter((item) => item.httpCode !== 200).map((item) => item.name) || [];
                    if (failedDeletions.length) {
                        showPopUpMessage(
                            DROPDOWN_CONTENT_TYPES.SUBJECT,
                            DROPDOWN_EDIT_TYPES.DELETION,
                            EDIT_RESPONSE_TYPES.ERROR,
                            failedDeletions.join(", ")
                        );
                    } else {
                        showPopUpMessage(
                            DROPDOWN_CONTENT_TYPES.SUBJECT,
                            DROPDOWN_EDIT_TYPES.DELETION,
                            EDIT_RESPONSE_TYPES.SUCCESS,
                            deletionsRes.map((item) => item.name).join(", ")
                        );
                    }
                    if (
                        !!subjectsToDelete.find((subject) => subject.subjectMetadata.subjectIdToOwner.id === subjectId)
                    ) {
                        dispatch(modalActions.setModalView(GlobalModalView.None));
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },
        [dispatch, openDeleteModal, showPopUpMessage, confirmDeletions, subjectId, subjects]
    );

    const saveSubjectsEdit = useCallback(
        async (changes: EntryChange[]) => {
            if (!subjectId) {
                return;
            }
            const findSubject = ({ itemId }: { itemId: string }): SubjectData | undefined =>
                subjects.find((subject) => subject.subjectMetadata.subjectIdToOwner.id === itemId);

            const { itemsToCreate, itemsToUpdate } = await processChanges<SubjectData>({
                subjectId,
                changes,
                findItemFn: findSubject,
                handleUpdateFn: handleSingleUpdate,
                handleCreationFn: handleSingleCreation,
                handleDeletionsFn: handleMultiDeletions,
            });

            await handleMultiCreations(itemsToCreate, DROPDOWN_CONTENT_TYPES.SUBJECT);
            await handleMultiUpdates(itemsToUpdate, DROPDOWN_CONTENT_TYPES.SUBJECT);

            dispatch(subjectActions.loadLibrarySubjects());
        },
        [dispatch, subjects, processChanges]
    );

    return saveSubjectsEdit;
};
