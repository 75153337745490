// DEPS
import React, { FunctionComponent, useRef, useEffect, useState, useCallback, ComponentProps } from "react";
import { createPortal } from "react-dom";
import FocusLock from "react-focus-lock";

// TYPES
import { YPositions, XPositions } from "p6m-viewData";

// STYLED COMPONENTS
import { ChildrenWrapper, PopupPortal, PopupBody } from "./styles";

// HELPERS
import { isModalOpen } from "../../../helpers/Modal";

export type PopupActions = {
    closePopup: () => void;
};

export type Props = ComponentProps<"div"> & {
    content: ((props: PopupActions) => React.ReactNode) | React.ReactNode;
    position?: [YPositions, XPositions];
    arrow?: boolean;
    fullWidth?: boolean;
    minChildWidth?: boolean;
    autoPosition?: boolean;
    onToggle?: (openState: boolean) => void;
    hasSetWidth?: boolean;
    isDisabled?: boolean;
};

const Popup: FunctionComponent<Props> = (props) => {
    const {
        children,
        content: ContentElement,
        position: positionFromProps = ["bottom", "center"],
        arrow = false,
        fullWidth = false,
        autoPosition = false,
        onToggle = () => {},
        minChildWidth = false,
        hasSetWidth = false,
        isDisabled = false,
        ...otherProps
    } = props;

    const [render, setRender] = useState(false);
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState(positionFromProps);
    const [coordinates, setCoordinates] = useState({ top: 0, left: 0, width: 0, height: 0 });

    const childrenRef = useRef<HTMLSpanElement | null>(null);

    const showPopup = useCallback(
        (e?: any) => {
            if (render !== visible) return;
            e?.preventDefault();
            onToggle(true);
            setRender(true);
            setTimeout(() => setVisible(true), 100);
        },
        [render, visible, onToggle]
    );

    const hidePopup = useCallback(() => {
        if (render !== visible) return;
        onToggle(false);
        setVisible(false);
        setTimeout(() => setRender(false), 300);
    }, [render, visible, onToggle]);

    useEffect(() => {
        if (visible) {
            document.addEventListener("click", hidePopup);
            return () => document.removeEventListener("click", hidePopup);
        }
    }, [visible, render, hidePopup]);

    useEffect(() => {
        setPosition(positionFromProps);
        //Since positionFromProps is array (=== referenceType), direct comparison in useEffect-Hook will fail. However: JSON.stringify() compares serialized string representation of positionFromProps, ensuring useEffect-hook updates position-state only when inner prop-values change.
    }, [JSON.stringify(positionFromProps)]);

    useEffect(() => {
        if (!render || !autoPosition || !childrenRef.current) return;
        const element = childrenRef.current;
        const { top, height } = element.getBoundingClientRect();
        const bottom = window.innerHeight - top - height;
        const bestPosition = top > bottom ? "top" : "bottom";
        setPosition(([, x]) => [bestPosition, x]);
    }, [render, autoPosition, childrenRef]);

    useEffect(() => {
        if (!render || !childrenRef.current) return;
        const element = childrenRef.current;
        let prevCoordinates: any = {};
        const interval = setInterval(() => {
            const { top, left, width, height } = element.getBoundingClientRect();
            const { top: prevTop } = prevCoordinates;

            if (top === prevTop) return;

            prevCoordinates = { top, left, width, height };
            setCoordinates(prevCoordinates);
        }, 30);

        return () => clearInterval(interval);
    }, [render, childrenRef]);

    const Content = typeof ContentElement === "function" ? <ContentElement closePopup={hidePopup} /> : ContentElement;

    return (
        <>
            <ChildrenWrapper
                ref={childrenRef}
                fullWidth={fullWidth}
                onClick={!isDisabled && !visible ? showPopup : undefined}
            >
                {children}
            </ChildrenWrapper>
            {!isDisabled &&
                render &&
                createPortal(
                    <PopupPortal {...coordinates}>
                        <PopupBody
                            onKeyUp={(e) => e.key === "Escape" && hidePopup()}
                            onClick={(e) => {
                                e.nativeEvent.stopImmediatePropagation();
                            }}
                            {...{ visible, position, arrow, minChildWidth, hasSetWidth }}
                            {...(otherProps as any)}
                        >
                            <FocusLock
                                disabled={isModalOpen() || !render}
                                returnFocus={() => true}
                            >
                                {Content}
                            </FocusLock>
                        </PopupBody>
                    </PopupPortal>,
                    document.body
                )}
        </>
    );
};

export default Popup;
