// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions as userActions, selectors as userSelectors } from "../../redux/user/userSlice";
import { actions as appStatusActions, selectors as appStatusSelectors } from "../../redux/appStatus/appStatusSlice";
import { actions as warningsActions, selectors as warningsSelectors } from "../../redux/warnings/warningsSlice";
import { actions as responseActions } from "../../redux/response/responseSlice";

// COMPONENTS
import Leaderboard from "./Leaderboard";

//API ENDPOINTS
import { getUserAvatars } from "../../networking/user";
import { getLeaderboard, getLeaderboardSchools } from "../../networking/leaderBoard";

//TYPES
import {
    User,
    UserStats,
    UserGroupsData,
    UserPreferences,
    LeaderboardData,
    LeaderboardTabs,
    LeaderboardStats,
    LeaderboardSchoolsData,
    SchoolData,
    LeaderboardDataTimeParam,
} from "p6m-user";

//HELPERS
import { getRandomUserName } from "../../helpers/User";

// HOOKS
import { useFirstPracticeResult } from "../../hooks/useFirstPracticeResult";

//STYLED COMPONENTS
import { PageWrapper } from "./styles";

export type LeaderBoardMetaData = {
    learnedCardsByAll: number;
    numUsers: number;
};

const SCHOOL_TABS: Array<LeaderboardTabs> = ["allSchools", "favourites"];
const INITIAL_LEADERBOARD_STATS: LeaderboardStats = {
    isCurrentUser: false,
    position: 0,
    userAvatarId: "",
    userName: "No Name",
    points: 0,
    activatedCards: 0,
    learnedCards: 0,
    learnedCardsForTest: 0,
    typedLetters: 0,
    userId: "",
};

const modifySchoolDataStats = (school: SchoolData, mySchoolId: number) => {
    const schoolData: LeaderboardStats = {
        ...INITIAL_LEADERBOARD_STATS,
        isCurrentUser: mySchoolId === school.id,
        position: school.position,
        userName: school.name,
        points: school.points,
        activatedCards: school.activatedCards,
        learnedCards: school.learnedCards,
        learnedCardsForTest: school.learnedCardsForTest,
        typedLetters: school.typedLetters,
        userId: "" + school.id,
        countryRegionName: school.countryRegionName,
        street: school.street,
        zip: school.zip,
        city: school.city,
        isFavorit: school.isFavorit,
    };
    return schoolData;
};
const modifyUserDataStats = (user: UserStats, useFirstName?: boolean, globalUserDnsId?: string) => {
    const isCurrentUser = globalUserDnsId === user.userId;
    const assignRandomUserName = !user.useFirstName || user.userName === "";
    const randomUserName: string | undefined =
        assignRandomUserName && !useFirstName && isCurrentUser ? getRandomUserName() : undefined;

    const userData: LeaderboardStats = {
        ...INITIAL_LEADERBOARD_STATS,
        isCurrentUser,
        userName: assignRandomUserName ? getRandomUserName() : user.userName,
        position: user.position,
        points: user.points,
        userAvatarId: user.userAvatarId,
        activatedCards: user.activatedCards,
        learnedCards: user.learnedCards,
        learnedCardsForTest: user.learnedCardsForTest,
        typedLetters: user.typedLetters,
        userId: user.userId,
    };
    return { userData, randomUserName };
};

const LeaderboardWrapper: React.FC = () => {
    const [week, setWeek] = useState<LeaderboardDataTimeParam>(0);

    const [data, setData] = useState<Array<LeaderboardStats> | undefined>(undefined);
    const [selectedTab, setSelectedTab] = useState<LeaderboardTabs>("all");
    const [allDataIsLoaded, setAllDataIsLoaded] = useState<boolean>(false);
    const [dataLoadingOffset, setDataLoadingOffset] = useState<number>(0);
    const [loadsAdditionalDataOnScroll, setLoadsAdditionalDataOnScroll] = useState<boolean>(false);
    const [refreshesData, setRefreshesData] = useState<boolean>(false);
    const isLoading: boolean = useSelector(appStatusSelectors.isLoading);

    const [metaData, setMetaData] = useState<LeaderBoardMetaData | undefined>(undefined);
    const [currentUserStats, setCurrentUserStats] = useState<UserStats | null>(null);
    const [mySchoolStats, setMySchoolStats] = useState<SchoolData | null>(null);
    const [showSelectSchoolButton, setShowSelectSchoolButton] = useState<boolean>(false);
    const [hasOwnSchool, setHasOwnSchool] = useState<boolean>(false);

    const [userAvatars, setUserAvatars] = useState<any>();

    const globalUser: User = useSelector(userSelectors.user);
    const userGroups: UserGroupsData = useSelector(userSelectors.userGroups);
    const userPreferences: UserPreferences = useSelector(userSelectors.userPreferences);
    const useFirstName = userPreferences.useFirstName;
    const mySchoolId = userPreferences.schoolId;
    const isUserCheater = useSelector(warningsSelectors.isUserCheater);
    const hasFirstPractice = useFirstPracticeResult();

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(
        function () {
            if (hasFirstPractice) return;
            history.push("/home");
        },
        [hasFirstPractice]
    );

    // fetch warnings
    useEffect(() => {
        dispatch(warningsActions.fetchWarnings());
    }, [dispatch]);

    const changeUserNamePreferences = () => {
        const updatedPreference: UserPreferences = { ...userPreferences };
        updatedPreference["useFirstName"] = !useFirstName;

        dispatch(
            userActions.saveUserPreferences({ oldPreferences: userPreferences, newPreferences: updatedPreference })
        );
    };

    const loadUserAvatars = useCallback(async () => {
        const userAvatars = await getUserAvatars();
        setUserAvatars(userAvatars);
    }, []);

    const getLeaderBoardData = useCallback(
        async ({
            week,
            start = dataLoadingOffset,
            chunkSize = 50,
        }: {
            week: LeaderboardDataTimeParam;
            start?: number;
            chunkSize?: number;
        }) => {
            if (isLoading) return;
            dispatch(appStatusActions.setLoading(true));
            setShowSelectSchoolButton(false);
            let allDataLength = 0;
            let learnedCardsByAll = 0;
            let numItems = 0;
            if (globalUser.userDnsId !== undefined) {
                if (SCHOOL_TABS.indexOf(selectedTab) > -1) {
                    const response: { data: { replyContent: LeaderboardSchoolsData } } = await getLeaderboardSchools(
                        selectedTab as Exclude<LeaderboardTabs, "all" | "mySchool" | "family">,
                        week,
                        start,
                        chunkSize,
                        globalUser.userDnsId
                    );

                    const leaderboardData = response.data.replyContent;
                    const mySchoolData: SchoolData = leaderboardData.mySchool;
                    const allSchools: SchoolData[] = leaderboardData.schools;

                    setData((previousSchools) => {
                        const previousSchoolsToInclude = start === 0 || !previousSchools ? [] : previousSchools;
                        return [
                            ...previousSchoolsToInclude,
                            ...allSchools.map((school) => modifySchoolDataStats(school, mySchoolData.id)),
                        ];
                    });
                    allDataLength = allSchools.length;
                    learnedCardsByAll = leaderboardData.learnedCardsByAll;
                    numItems = leaderboardData.numSchools;

                    if (week > -1 && selectedTab === "allSchools" && !mySchoolData?.isMine) {
                        // user hasn't selected a school yet
                        setShowSelectSchoolButton(true);
                    }
                    setMySchoolStats(mySchoolData);

                    // clear data from another tab to use the correct ranking position
                    setCurrentUserStats(null);
                } else {
                    const response: { data: { replyContent: LeaderboardData } } = await getLeaderboard(
                        selectedTab as Exclude<LeaderboardTabs, "allSchools" | "favourites">,
                        week,
                        start,
                        chunkSize,
                        globalUser.userDnsId
                    );
                    const leaderboardData = response.data.replyContent;
                    const allUsers: UserStats[] = leaderboardData.page;

                    let localRandomUserName: string | undefined = undefined;
                    setData((previousUsers) => {
                        const previousUsersToInclude = start === 0 || !previousUsers ? [] : previousUsers;
                        return [
                            ...previousUsersToInclude,
                            ...allUsers.map((user) => {
                                const { userData, randomUserName } = modifyUserDataStats(
                                    user,
                                    useFirstName,
                                    globalUser.userDnsId
                                );
                                localRandomUserName = randomUserName;
                                return userData;
                            }),
                        ];
                    });

                    allDataLength = allUsers.length;
                    learnedCardsByAll = leaderboardData.learnedCardsByAll;
                    numItems = leaderboardData.numUsers;

                    const currentUser = leaderboardData.currentUser;
                    if (selectedTab === "mySchool" && !currentUser) {
                        // user hasn't selected a school yet
                        setShowSelectSchoolButton(true);
                    } else if (!currentUser.useFirstName) {
                        if (localRandomUserName) {
                            currentUser.userName = localRandomUserName;
                        } else {
                            currentUser.userName = getRandomUserName();
                        }
                    }
                    setCurrentUserStats(currentUser);

                    // clear data from another tab to use the correct ranking position
                    setMySchoolStats(null);
                }

                const newDataOffset = start + chunkSize;
                const newDataLength = (data || []).length + allDataLength;
                setDataLoadingOffset(newDataOffset);
                if (newDataLength < newDataOffset) {
                    setAllDataIsLoaded(true);
                }

                if (metaData) {
                    setMetaData((metaDataToUpdate) => {
                        if (metaDataToUpdate) {
                            metaDataToUpdate.learnedCardsByAll = learnedCardsByAll;
                            metaDataToUpdate.numUsers = numItems;
                        }
                        return metaDataToUpdate;
                    });
                } else {
                    setMetaData({ learnedCardsByAll, numUsers: numItems });
                }
            }
            dispatch(appStatusActions.setLoading(false));
        },
        [dispatch, globalUser.userDnsId, useFirstName, selectedTab, dataLoadingOffset, isLoading, data]
    );

    const resetData = useCallback(() => {
        setData(undefined);
        setAllDataIsLoaded(false);
        setDataLoadingOffset(0);
    }, []);

    const refreshData = useCallback(() => {
        !refreshesData && setRefreshesData(true);
    }, [refreshesData]);

    const loadAdditionalDataOnScroll = useCallback(() => {
        !loadsAdditionalDataOnScroll && setLoadsAdditionalDataOnScroll(true);
    }, [loadsAdditionalDataOnScroll]);

    const loadDataIncludingUser = useCallback(
        (position: number) => {
            getLeaderBoardData({ week, start: 0, chunkSize: position + 50 });
        },
        [getLeaderBoardData]
    );

    const changeSelectedTab = useCallback(
        (tab: LeaderboardTabs) => {
            if (tab !== selectedTab) {
                setSelectedTab(tab);
                resetData();
            }
        },
        [resetData, selectedTab]
    );

    const changeWeek = useCallback(
        (newWeek: LeaderboardDataTimeParam) => {
            if (week !== newWeek) {
                setWeek(newWeek);
                resetData();
            }
        },
        [resetData, week]
    );

    useEffect(() => {
        const loadData = async () => {
            await getLeaderBoardData({ week, start: refreshesData ? 0 : dataLoadingOffset });
        };

        if (!data || loadsAdditionalDataOnScroll || refreshesData) {
            loadData();
            setLoadsAdditionalDataOnScroll(false);
            setRefreshesData(false);
        }
    }, [data, getLeaderBoardData, week, loadAdditionalDataOnScroll, refreshesData, dataLoadingOffset]);

    useEffect(() => {
        loadUserAvatars();
    }, [loadUserAvatars]);

    useEffect(() => {
        setHasOwnSchool(!!mySchoolId);
    }, [mySchoolId]);

    const showWarning = useCallback(
        (message: string) => {
            dispatch(responseActions.showResponse({ type: "WARNING", text: [message] }));
        },
        [dispatch]
    );

    return (
        <PageWrapper id="leaderboard">
            <Leaderboard
                leaderBoardData={data}
                metaData={metaData}
                user={globalUser}
                week={week}
                changeWeek={changeWeek}
                onScroll={loadAdditionalDataOnScroll}
                allDataIsLoaded={allDataIsLoaded}
                refreshData={refreshData}
                loadDataIncludingUser={loadDataIncludingUser}
                isLoading={isLoading}
                useFirstName={useFirstName}
                toggleUseFirstName={changeUserNamePreferences}
                userAvatars={userAvatars}
                currentUserStats={currentUserStats}
                groups={userGroups.groups || []}
                selectedTab={selectedTab}
                setSelectedTab={changeSelectedTab}
                isSchoolTab={SCHOOL_TABS.indexOf(selectedTab) > -1}
                mySchoolStats={mySchoolStats}
                showSelectSchoolButton={showSelectSchoolButton}
                hasOwnSchool={hasOwnSchool}
                setHasOwnSchool={setHasOwnSchool}
                isUserCheater={isUserCheater}
                showWarning={showWarning}
            />
        </PageWrapper>
    );
};

export default LeaderboardWrapper;
