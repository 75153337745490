// REACT
import React, { FunctionComponent, useRef, useEffect, useState, useCallback, ComponentProps } from "react";

// STYLED COMPONENTS
import type { TCoordinates } from "./styles";
import { ChildrenWrapper, PopupPlacement, PopupBody } from "./styles";

type YPositions = "top" | "bottom";
type XPositions = "center" | "left" | "right";

export type PopupActions = {
    closePopup: () => void;
};

export type Props = ComponentProps<"div"> & {
    content: ((props: PopupActions) => React.ReactNode) | React.ReactNode;
    position?: [YPositions, XPositions];
    arrow?: boolean;
    minChildWidth?: boolean;
    onToggle?: (openState: boolean) => void;
};

const Popup: FunctionComponent<Props> = (props) => {
    const {
        children,
        content: ContentElement,
        position = ["bottom", "center"],
        arrow = false,
        onToggle = () => {},
        minChildWidth = false,
        ...otherProps
    } = props;

    const [render, setRender] = useState(false);
    const [visible, setVisible] = useState(false);

    const showPopup = useCallback(
        (e?: any) => {
            if (render !== visible) return;
            e?.preventDefault();
            onToggle(true);
            setRender(true);
            setTimeout(() => setVisible(true), 100);
        },
        [render, visible, onToggle]
    );

    const hidePopup = useCallback(() => {
        if (render !== visible) return;
        onToggle(false);
        setVisible(false);
        setTimeout(() => setRender(false), 300);
    }, [render, visible, onToggle]);

    useEffect(() => {
        if (visible) {
            document.addEventListener("click", hidePopup);
            return () => document.removeEventListener("click", hidePopup);
        }
    }, [visible, hidePopup]);

    const [coordinates, setCoordinates] = useState<TCoordinates>({});

    const childrenRef = useRef<HTMLSpanElement | null>(null);

    useEffect(() => {
        if (!childrenRef.current) return;
        const element = childrenRef.current;
        const { width, height } = element.getBoundingClientRect();
        let top, left, bottom, right;
        if (position[0] === "top") {
            bottom = height;
        } else {
            // bottom
            top = height;
        }
        if (position[1] === "left") {
            right = width;
        } else if (position[1] === "right") {
            left = width;
        } else {
            // center
            right = width / 2;
        }
        setCoordinates({ top, left, bottom, right });
    }, [childrenRef, position]);

    const Content = typeof ContentElement === "function" ? <ContentElement closePopup={hidePopup} /> : ContentElement;

    return (
        <>
            <ChildrenWrapper
                ref={childrenRef}
                onClick={!visible ? showPopup : undefined}
            >
                {children}
                {render && (
                    <PopupPlacement {...coordinates}>
                        <PopupBody
                            onClick={(e) => {
                                e.nativeEvent.stopImmediatePropagation();
                            }}
                            {...{ visible, position, arrow, minChildWidth }}
                            {...(otherProps as any)}
                        >
                            {Content}
                        </PopupBody>
                    </PopupPlacement>
                )}
            </ChildrenWrapper>
        </>
    );
};

export default Popup;
