import { ampli, DefaultConfiguration, IdentifyProperties } from "../ampli";
import { ServerZone } from "@amplitude/analytics-types";
import { MemoryStorage } from "@amplitude/analytics-core";
import { Storage, UserSession, Event } from "@amplitude/analytics-types";
import { LoginResponseReplyContent } from "p6m-response";
import { UserPreferences } from "p6m-user";
import { AddAppVersionPlugin } from "../logging/plugins/AmpliEnrichAppVersion";
import { hashUserDnsId, getAmplitudeThemeName } from "./Tracking";
import { purgeAmplitudeStorageFromClient } from "./Cookies";
import { Theme } from "../themes/constants";

type UserDataProp = Pick<
    LoginResponseReplyContent,
    "roles" | "email" | "firstName" | "userDnsId" | "gdprStatus" | "isFirstWebLogin"
>;

type UserPreferencesProp = Pick<UserPreferences, "lang" | "userAvatarId">;

export function amplitudeLoadAndInit(userData: UserDataProp, userPreferences: UserPreferencesProp, theme?: Theme) {
    const { roles, email, firstName, userDnsId, gdprStatus = "", isFirstWebLogin } = userData;
    const { lang, userAvatarId: avatarId } = userPreferences;

    const userRole = getUserRole(roles, email, firstName);

    const hashedUserId = hashUserDnsId(userDnsId, email);
    const doNotAnalyzeData = !gdprStatus.includes("analytics");
    const storageOptions: { cookieStorage?: Storage<UserSession>; storageProvider?: Storage<Event[]> } = {};

    if (doNotAnalyzeData) {
        purgeAmplitudeStorageFromClient();
        storageOptions.cookieStorage = new MemoryStorage();
        storageOptions.storageProvider = new MemoryStorage();
    }

    const userLang = getUserLang(lang);

    const userTheme = getAmplitudeThemeName(theme);

    const result = ampli.load({
        environment: process.env.NODE_ENV === "production" ? "production" : "development",
        disabled: !hashedUserId || doNotAnalyzeData,
        client: {
            configuration: {
                ...DefaultConfiguration,
                serverZone: ServerZone.EU,
                disableCookies: doNotAnalyzeData,
                optOut: doNotAnalyzeData,
                ...storageOptions,
            },
        },
    });

    ampli.client.add(new AddAppVersionPlugin());
    ampli.identify(hashedUserId, {
        role: { userRole },
        allRoles: roles,
        app_language: userLang,
        avatar: avatarId,
        skin: userTheme,
    });

    if (isFirstWebLogin) {
        ampli.firstLoginToPxp();
    }

    return result;
}

function getUserRole(roles: UserDataProp["roles"], email: UserDataProp["email"], firstName: UserDataProp["firstName"]) {
    let userRole = "";
    if (roles.includes("teacher")) {
        userRole = "teacher";
    } else if (roles.includes("parent")) {
        userRole = "parent";
    } else if (roles.includes("student")) {
        userRole = "student";
    } else if (
        !email ||
        !firstName ||
        !firstName.length ||
        firstName === "Anonymous" ||
        email.indexOf("@phase-6.org") >= 0
    ) {
        userRole = "anonymous";
    }

    return userRole;
}

function getUserLang(lang?: string) {
    type AppLanguages = "en_GB" | "es" | "de" | "pl" | string;
    type AmlyLangRelations = Partial<Record<AppLanguages, Required<IdentifyProperties>["app_language"]>>;
    const relations: AmlyLangRelations = {
        en_GB: "english",
        de: "german",
        es: "spanish",
        pl: "polish",
    };

    return lang ? relations[lang] : undefined;
}
