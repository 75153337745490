// LIBRARIES
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChangeEmailParamsType, ResendPasswordParamsType } from "p6m-networking";
import { GlobalModalView } from "../../../helpers/Modal";

// COMPONENTS
import { selectors as userSelectors, actions as userActions } from "../../../redux/user/userSlice";
import { actions as modalActions } from "../../../redux/modal/modalSlice";
import ConfirmEmailModal from "./ConfirmEmailModal";

const ConfirmEmailModalView: React.FC = () => {
    const user = useSelector(userSelectors.user);
    const dispatch = useDispatch();

    const close = async () => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
    };

    const onChangeEmail = async (userEmail: string) => {
        const payload: ChangeEmailParamsType = {
            oldlogin: !!user.email ? user.email : "",
            newlogin: userEmail,
        };
        dispatch(userActions.changeEmail(payload));
    };

    const onResendEmail = async (userEmail: string) => {
        const payload: ResendPasswordParamsType = {
            login: userEmail,
        };
        dispatch(userActions.confirmEmail(payload));
    };

    return (
        <ConfirmEmailModal
            userEmail={!!user.email ? user.email : ""}
            onChangeEmail={onChangeEmail}
            onResendEmail={onResendEmail}
            close={close}
        />
    );
};

export default ConfirmEmailModalView;
