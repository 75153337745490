// REACT
import React, { FunctionComponent, memo } from "react";
import { useT, T } from "@transifex/react";

// COMPONENTS
import Popup from "../../../basic/popup/Popup";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

// TYPES
import { Directions } from "p6m-learning";
import { CoverImage } from "p6-react-shared";
import { SubjectData, SubjectUnitData } from "p6m-subjects";

// UTILS
import { getSubjectCover } from "../../../../helpers/Subjects";
import { getLogoById } from "../../../../helpers/PublisherLogos";

// STYLED COMPONENTS
import {
    BarWrapper,
    MenuIcon,
    MenuItem,
    DirectionSubMenuItem,
    PopupEntryContainer,
    MenuEntryText,
    MenuList,
    IconContainer,
    ContextMenuPopup,
    ContextMenuHeader,
    HeaderText,
    PublisherImage,
    SubjectTitle,
    UnitTitle,
} from "./styles";

export type Props = {
    subject?: SubjectData;
    unit?: SubjectUnitData;
    showEditItem: boolean;
    showFeedback: boolean;
    isAutoplayEnabled: boolean;
    direction?: "both" | Directions;
    onEdit: () => void;
    onToggleAudioSetting: () => void;
    onChangeDirection: (newDirection: Props["direction"]) => void;
    onFeedback: () => void;
};

const CardMenu: FunctionComponent<Props> = (props) => {
    const {
        subject,
        unit,
        direction,
        showEditItem,
        showFeedback,
        isAutoplayEnabled,
        onEdit,
        onToggleAudioSetting,
        onChangeDirection,
        onFeedback,
    } = props;
    const t = useT();

    const { subjectContent: { name: subjectName = "" } = {} } = subject || {};

    const { unitContent: { name: unitName = "" } = {}, unitId: { ownerId = "" } = {} } = unit || {};

    const t_normalDirTitle = t("Practise this card in the original language direction.", {});
    const t_normalDirDescr = t(
        "The card will be practised in the language direction that you defined when you created it.",
        {}
    );
    const t_oppositeDirTitle = t("Practise this card in the opposite language direction.", {});
    const t_oppositeDirDescr = t("The language direction will be reversed.", {});
    const t_bothDirTitle = t("Practise this card in both language directions", {});
    const t_bothDirDescr = t(
        "You will have to practise the card in both language directions.{newLine}You will be asked to enter one of them, question or answer, respectively.",
        { newLine: <br /> }
    );

    const directions = {
        normal: [t_normalDirTitle, t_normalDirDescr, "arrow-right"],
        opposite: [t_oppositeDirTitle, t_oppositeDirDescr, "arrow-left"],
        both: [t_bothDirTitle, t_bothDirDescr, "arrows-right-left"],
    };

    const cover = subject ? getSubjectCover(subject) : "";
    const publisherLogo = ownerId ? getLogoById(ownerId) : "";

    const menuItems = [
        showFeedback && (
            <MenuItem
                key={0}
                onClick={onFeedback}
            >
                <MenuIcon name="feedback-2" />
                <MenuEntryText>
                    <T _str="Suggest improvement" />
                </MenuEntryText>
            </MenuItem>
        ),
        <MenuItem
            key={1}
            onClick={onToggleAudioSetting}
        >
            <MenuIcon name="speaker" />
            <MenuEntryText>
                <T _str={isAutoplayEnabled ? "Autoplay on" : "Autoplay off"} />
            </MenuEntryText>
        </MenuItem>,
        showEditItem && (
            <MenuItem
                key={2}
                onClick={onEdit}
            >
                <MenuIcon name="edit" />
                <MenuEntryText>
                    <T _str="Edit Card" />
                </MenuEntryText>
            </MenuItem>
        ),
        direction && (
            <MenuItem key={3}>
                <Popup
                    position={["bottom", "right"]}
                    content={({ closePopup }) => (
                        <MenuList>
                            {Object.entries(directions).map(([key, [title, descr, icon]]) => (
                                <DirectionSubMenuItem
                                    key={key}
                                    onClick={() => {
                                        closePopup();
                                        onChangeDirection(key as Directions);
                                    }}
                                    active={key === direction}
                                >
                                    <MenuIcon name={icon} />
                                    <div>
                                        <h3>{title}</h3>
                                        <p>{descr}</p>
                                    </div>
                                </DirectionSubMenuItem>
                            ))}
                        </MenuList>
                    )}
                >
                    <PopupEntryContainer>
                        <MenuIcon name="arrow-both" />
                        <MenuEntryText>
                            <T _str="Language direction" />
                        </MenuEntryText>
                    </PopupEntryContainer>
                </Popup>
            </MenuItem>
        ),
    ].filter((item) => !!item);

    return !!menuItems.length ? (
        <BarWrapper>
            <ContextMenuPopup
                content={
                    <MenuList>
                        <ContextMenuHeader>
                            {cover && (
                                <CoverImage
                                    imageId={cover}
                                    size="small"
                                    alt=""
                                />
                            )}
                            <HeaderText withCover={cover !== ""}>
                                <SubjectTitle>{subjectName}</SubjectTitle>
                                <UnitTitle>{unitName}</UnitTitle>
                            </HeaderText>
                            {publisherLogo && (
                                <PublisherImage
                                    src={publisherLogo}
                                    alt={t("Publisher Logo", { _tags: "cardMenu, altText" })}
                                />
                            )}
                        </ContextMenuHeader>
                        {menuItems.map((item) => item)}
                    </MenuList>
                }
                position={["bottom", "right"]}
                arrow
            >
                <IconContainer>
                    <PhaseSixIcon
                        name={"context-menu"}
                        data-qa="menu-button"
                    />
                </IconContainer>
            </ContextMenuPopup>
        </BarWrapper>
    ) : (
        <></>
    );
};

export default memo(CardMenu);
