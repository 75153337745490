import { SubjectUnitCard } from "p6m-subjects";
import { shuffle, chunk, xor } from "lodash";
import { Types, Directions, Item, ResultActions } from "p6m-learning";
import { StatisticData, submitAnswer } from "../networking/practice";

export function generateItems(
    cards: SubjectUnitCard[],
    direction: number,
    type: number,
    constants: Partial<ReturnType<typeof getSlicesConstants>> = {}
) {
    if (!cards?.length) return [];

    const { length, types, directions, directionsMap } = { ...getSlicesConstants(direction, type), ...constants };

    const cardsWithIndexes = cards.map((card, index) => ({ ...card, index }));
    const groupedCards = chunk(cardsWithIndexes, length || cardsWithIndexes.length);
    //shallow difference-comparison of array-values
    const shuffleCards = xor(types, ["memorize", "practice"]).length !== 0;

    const orderedCardsIndicesByGroups = groupedCards.map((group) => [
        group.filter((card) => card.normal).map((card) => card.index),
        group.filter((card) => card.opposite && !card.normal).map((card) => card.index),
    ]);

    let slice: number = 0;

    //items/cards are being conditionally shuffled while staying sorted by group and direction Items
    const shuffledYetSortedItems = orderedCardsIndicesByGroups.reduce((result, group) => {
        types.forEach((type) => {
            const replace = directionsMap[type];
            const finalDirections = replace || directions;
            const hasOpposite = finalDirections.length > 1;
            finalDirections.forEach((direction) => {
                group.forEach((chunk) => {
                    (shuffleCards ? shuffle(chunk) : chunk).forEach((cardIndex) => {
                        result.push({
                            cardIndex,
                            slice,
                            type,
                            resolved: false,
                            direction,
                            submitCount: 0,
                            hasOpposite,
                        });
                    });
                    slice += 1;
                });
            });
        });
        return result;
    }, [] as Item[]);

    return shuffledYetSortedItems;
}

function getSlicesConstants(direction: number, type: number) {
    const mixedLimit: number = parseInt(process.env.REACT_APP_PRACTICE_MIXED_SLICE_LIMIT || "5");
    const length = [
        0, // for memorize
        8, // for dragndrop
        mixedLimit, // for mixed with stack
        0, // for practice type
    ][type];
    const types = (
        [
            ["memorize", "practice"], // for memorize
            ["drag", "practice"], // for dragndrop
            ["memorize", "practice"], // for mixed with stack
            ["practice"], // for practice type
        ] as Types[][]
    )[type];
    const directions = (
        [
            ["normal"], // for normal direction
            ["normal", "opposite"], // for both directions
            ["opposite"], // for opposite direction
        ] as Directions[][]
    )[direction];
    const directionsMap = (
        [
            {}, // for normal direction
            {
                // for both directions
                memorize: ["both"], // filter opposite if both directions and memorize
                drag: ["both"], // filter opposite if both directions and drag
            },
            {}, // for opposite direction
        ] as Partial<Record<Types, Item["direction"][]>>[]
    )[direction];

    return {
        length,
        types,
        directions,
        directionsMap,
    };
}

export function getNextItemIndex(items: Item[], currentItemIndex: number, isBack: boolean = false): number {
    const item = items[currentItemIndex];
    const slice = items.filter(({ slice }) => slice === item.slice);
    const itterator: number = isBack ? 0 : 1;
    if (slice.length) {
        const from = slice.indexOf(item);
        const nextItem = findFromIndex(slice, from + itterator, ({ resolved }) => !resolved, isBack);
        if (nextItem) return items.indexOf(nextItem);
    }
    const nextItem = findFromIndex(items, currentItemIndex + itterator, ({ resolved }) => !resolved, isBack);
    if (!nextItem) return -1;
    return items.indexOf(nextItem);
}

export function getNextSliceItemIndex(items: Item[], currentItemIndex: number, isBack: boolean = false): number {
    const item = items[currentItemIndex];
    const { slice: currentSlice } = item;
    const nextSliceItem = findFromIndex(
        items,
        currentItemIndex,
        ({ resolved, slice }) => {
            if (slice === currentSlice) return false;
            return !resolved;
        },
        isBack
    );
    if (!nextSliceItem) return -1;
    return items.indexOf(nextSliceItem);
}

function findFromIndex<T>(
    array: T[],
    from: number,
    callback: (item: T) => boolean,
    isBack: boolean = false
): T | undefined {
    if (from <= 0 || from >= array.length) from = 0;
    const arrayCopy = [...array];
    arrayCopy.push(...arrayCopy.splice(0, from));
    if (isBack) arrayCopy.reverse();
    return arrayCopy.find(callback);
}

type CardHistoryItem = {
    action: ResultActions;
    phase: number;
    submitCount: number;
    isDue: boolean;
};
type CardHistory = CardHistoryItem[];

type GetCardItemActions = (item: Item, data?: CardHistoryItem) => CardHistory;
type AddCardItemAction = (item: Item, data: CardHistoryItem) => CardHistory;
type ClearCardsHistory = () => void;

export function createCardItemHistory(): [GetCardItemActions, AddCardItemAction, ClearCardsHistory] {
    let history: Record<string, CardHistory> = {};

    const getItemActionsHistory = function (item: Item, data?: CardHistoryItem) {
        const { direction, cardIndex, slice } = item;

        const itemKey: string = `${slice}${direction}${cardIndex}`;

        if (!history[itemKey]) history[itemKey] = [];

        if (data) history[itemKey].unshift(data);

        return history[itemKey];
    };

    const addItemAction = function (item: Item, data: CardHistoryItem) {
        return getItemActionsHistory(item, data);
    };

    const clearItemsHistory = function () {
        history = {};
    };

    return [getItemActionsHistory, addItemAction, clearItemsHistory];
}

function getCurrentHistory(history: CardHistory): CardHistoryItem {
    return history[0];
}

function getPrevHistory(history: CardHistory): CardHistoryItem | undefined {
    return history[1];
}

function getFirstHistoryItem(history: CardHistory): CardHistoryItem {
    const lastItemIndex = history.length - 1;
    return history[lastItemIndex];
}

export function getSubmitCount(history: CardHistory): number {
    const { submitCount, action } = getCurrentHistory(history);
    const { action: prevAction } = getPrevHistory(history) || {};

    if (action === "onAsCorrect" && prevAction === "onCompare") {
        return submitCount;
    }

    return submitCount + 1;
}

export function getResolved(history: CardHistory, answerCorrect: boolean) {
    const { action } = getCurrentHistory(history);
    const { action: prevAction } = getPrevHistory(history) || {};

    if (action === "onRetype") return false;
    if (action === "onAsCorrect" && prevAction === "onRetype") return false;

    return answerCorrect;
}

export function getPhaseBeforeAnswer(history: CardHistory) {
    const { action, phase } = getCurrentHistory(history);
    const { action: prevAction, phase: prevPhase } = getPrevHistory(history) || {};

    if (!prevPhase) return phase;
    if (["onWasWrong", "onAsCorrect"].includes(action) && prevAction === "onCompare") return prevPhase;

    return phase;
}

function preventSubmitRequestTypingMode(history: CardHistory, isDue: boolean) {
    const { action, submitCount, phase } = getCurrentHistory(history);
    const { action: prevAction = "" } = getPrevHistory(history) || {};

    const isReallyDue: boolean = isDue || phase === 1;
    const isForcedAction = ["onAsCorrect", "onWasWrong"].includes(action);

    if (action === "onRetype") return true;

    if (isForcedAction) {
        if (prevAction === "onRetype") return true;
        if (submitCount > 1 && phase > 1) return true;
    }

    return !isReallyDue && !isForcedAction;
}

function preventSubmitRequestNoTypingMode(history: CardHistory) {
    const { submitCount, phase } = getCurrentHistory(history);

    return submitCount >= 1 && phase > 1;
}

export function preventSubmitRequest(history: CardHistory, isDue: boolean, isTyping: boolean) {
    const relation = [
        () => preventSubmitRequestNoTypingMode(history),
        () => preventSubmitRequestTypingMode(history, isDue),
    ];

    const action = relation[+isTyping];

    return action();
}

export function preventPrepareForTestRequest(history: CardHistory, isTyping: boolean) {
    const { submitCount } = getCurrentHistory(history);

    if (submitCount > 0) return true;

    return preventSubmitRequest(history, true, isTyping);
}

export function getStatisticType(isCorrect: boolean, isRegularPractice: boolean): StatisticData["Type"] {
    const statisticTypes: StatisticData["Type"][][] = [
        ["PREPARE_INCORRECT_ANSWER", "PREPARE_CORRECT_ANSWER"],
        ["INCORRECT_ANSWER", "CORRECT_ANSWER"],
    ];

    return statisticTypes[+isRegularPractice][+isCorrect];
}

export function getPracticeRequestType(isTyping: boolean, action: ResultActions) {
    type RequestTypes = Parameters<typeof submitAnswer>[0]["data"]["type"];
    const requestTypesRelations: Partial<Record<ResultActions, RequestTypes>>[] = [
        {
            onAsCorrect: "NO_TYPE_RIGHT",
            onWasWrong: "NO_TYPE_WRONG",
        },
        {
            onAsCorrect: "I_WAS_RIGHT",
            onWasWrong: "I_WAS_WRONG",
        },
    ];

    return requestTypesRelations[+isTyping][action] || "NORMAL";
}

export function getIsRegularPractice(isPrepareForTest: boolean, history: CardHistory) {
    if (!isPrepareForTest) return true;

    const { isDue } = getFirstHistoryItem(history); // getting first due status of the card to know how practice was started

    return isDue;
}
